import { all, call, put, takeLatest } from 'redux-saga/effects';
import { LIFECYCLE, KEY } from 'redux-pack';

import {
  addBreadcrumb,
  captureMessage,
  configureScope,
} from '@quintoandar/fwp-error-monitoring';
import {
  loginSucceeded,
  refreshProfile,
} from '@quintoandar/bioma-auth/containers/actions';
import {
  LOGIN_SUCCESS,
  REFRESH_PROFILE,
  LOGOUT,
} from '@quintoandar/bioma-auth/containers/constants';
import { hasAuthCookie } from '@quintoandar/wat-cookie';

import { checkIsAgent } from 'api/agents/agent';
import { sendOfferLink } from 'api/agents/offer';
import { fetchAgentRating } from 'api/agents/review';
import { fetchHouseUserDetails } from 'api/agents/house';

import {
  loginToFirebaseWithToken,
  logoutFromFirebase,
} from 'api/firebase/auth';

import {
  fetchAgentDataSuccess,
  offerLinkFailure,
  offerLinkSuccess,
  checkedAgent,
  unauthorizedAgent,
} from 'containers/App/actions';
import {
  AUTH_FLOW_FAILURE,
  AUTH_FLOW_SUCCESS,
  CHECKED_AGENT_FAILURE,
  FETCH_AGENT_RATING,
  FIREBASE_AUTH_STATE_CHANGED,
  OFFER_LINK_REQUEST,
  USERS_ME_URL,
} from 'containers/App/constants';

import { AGENT_RATING_DAYS_LIMIT } from 'enums/ratingLimit';
import { CONTRACT, OFFER, VISIT, DOCUMENTATION } from 'enums/rentProgressTypes';

import { authenticate } from '../../api/agents/firebase';
import { fetchUsersDetails } from '../../api/agents/user';
import { parseSnapshotToArray } from '../../utils';
import {
  logoutAndRedirect,
  redirectToForbidden,
  redirectToLogin,
} from '../../utils/sagaUtils';
import { FETCH_VISITS_SUCCESS } from '../DoneVisits/constants';
import { firebaseLoginSuccess } from '../Firebase/actions';
import { FETCH_CONTRACTS_SUCCESS } from '../RentProgress/Contracts/constants';
import { FETCH_DOCUMENTATIONS_SUCCESS } from '../RentProgress/Documentations/constants';
import { FETCH_OFFERS_SUCCESS } from '../RentProgress/Offers/constants';
import handleBusinessContext from './business-context-handler';

import {
  getSuccessHouseActionType,
  getIdFromType,
  parseFetchHouseQuery,
  parseFetchUserQuery,
  getSuccessUserActionType,
  mapSearchTypeToRentProgressType,
} from './helpers';

/**
 * Send offer link for tenant
 * @param {object} action
 * @returns {IterableIterator<*>}
 */
export function* sendOfferLinkRequestSaga(action) {
  try {
    yield call(sendOfferLink, { payload: action.payload });
    yield put(offerLinkSuccess());
  } catch (e) {
    yield put(offerLinkFailure(e.message));
  }
}

/**
 * Logout from Firebase
 * @returns {IterableIterator<*>}
 */
export function* firebaseLogoutSaga() {
  yield call(logoutFromFirebase);
}

export function* fetchAgentRatingSaga({ payload }) {
  try {
    addBreadcrumb({
      message: 'Will fetch agent rating',
      category: 'fetch',
      data: { hasAuthCookie: hasAuthCookie() },
    });

    const { hasReviews, rating } = yield call(fetchAgentRating, {
      businessContext: payload?.businessContext,
      days: AGENT_RATING_DAYS_LIMIT,
    });
    const agentData = { hasReviews, rating };
    yield put(fetchAgentDataSuccess({ agentData }));
  } catch (error) {
    captureMessage('Failed to fetch agent rating', {
      extra: {
        error,
      },
    });
  }
}

function* firebaseLogin(firebaseToken) {
  if (!firebaseToken) {
    throw new Error('Missing Firebase Token from LOGIN_SUCCESS payload');
  }

  yield call(loginToFirebaseWithToken, firebaseToken);
  yield put(firebaseLoginSuccess());
}

function* checkActiveAgent() {
  try {
    yield call(checkIsAgent);
  } catch (e) {
    throw new Error(CHECKED_AGENT_FAILURE);
  }
  yield put(checkedAgent(true));
}

function* authFlow(action) {
  if (!action.loginData) {
    throw new Error('Unexpected payload');
  }

  const { firebase_json_web_token: firebaseToken } = action.loginData;

  yield* firebaseLogin(firebaseToken);
  yield* checkActiveAgent();

  yield put({ type: AUTH_FLOW_SUCCESS });
}

export function* authFlowSaga(action) {
  try {
    yield* authFlow(action);
  } catch (error) {
    yield put({ type: AUTH_FLOW_FAILURE, error });
    if (error.message === CHECKED_AGENT_FAILURE) {
      yield* redirectToForbidden();
      yield put(unauthorizedAgent());
    } else {
      yield* logoutAndRedirect();
    }
  }
}

export function* firebaseAuthStateSaga() {
  try {
    const isAuthenticated = yield call(hasAuthCookie);
    if (!isAuthenticated) {
      yield* redirectToLogin();
      return;
    }

    const authResponse = yield call(authenticate, {});
    yield put(loginSucceeded(authResponse));
    yield put(refreshProfile(USERS_ME_URL));
  } catch (error) {
    captureMessage('An error has occured while firebase changed state', {
      extra: {
        error,
      },
    });
  }
}

export function* refreshProfileSuccess(action) {
  if (!action.payload) return;

  const { email, id, name, agentId, businessContext } = action.payload;

  const refreshedProfile = agentId !== 0;
  const agentData = {
    businessContext: handleBusinessContext({ businessContext }),
  };

  try {
    yield* checkActiveAgent();
    configureScope((scope) => {
      scope.setUser({
        email,
        id,
        name,
      });
    });
    yield put(fetchAgentDataSuccess({ agentData, refreshedProfile }));
  } catch (error) {
    yield* refreshProfileFailure();
  }
}

export function* refreshProfileFailure() {
  yield* redirectToForbidden();
  yield put(unauthorizedAgent());
}

export function* refreshProfileSaga(action) {
  if (action.meta[KEY.LIFECYCLE] === LIFECYCLE.SUCCESS) {
    yield* refreshProfileSuccess(action);
  } else if (action.meta[KEY.LIFECYCLE] === LIFECYCLE.FAILURE) {
    yield* refreshProfileFailure();
  }
}

export function* fetchUserData(action, type) {
  const { data: snapshotResult } = action.payload;
  const fieldName = mapSearchTypeToRentProgressType(type);
  const typedKey = getIdFromType(fieldName);

  const queryResults = parseSnapshotToArray(snapshotResult);
  const parsedQueryResults = queryResults.map(parseFetchUserQuery(typedKey));
  const usersId = parsedQueryResults.map((queryResult) => queryResult.tenantId);

  if (usersId.length > 0) {
    const usersDetails = yield call(fetchUsersDetails, { usersId });

    const actionFetchUserSuccess = getSuccessUserActionType(type);

    yield all(
      usersDetails.map((userDetail) => {
        const filteredResult = parsedQueryResults.filter(
          ({ tenantId }) => tenantId === userDetail.id,
        );

        return filteredResult.map((result) => {
          const entityId = result[typedKey];
          const actionPayload = { entityId, ...userDetail };
          return put(actionFetchUserSuccess(actionPayload));
        });
      }),
    );
  }
}

export const fetchUserDataBuilder = (type) => (action) =>
  fetchUserData(action, type);

export function* fetchHouseData(action, type) {
  const { data: snapshotResult, businessContext } = action.payload;
  const fieldName = mapSearchTypeToRentProgressType(type);
  const typedKey = getIdFromType(fieldName);

  const queryResults = parseSnapshotToArray(snapshotResult);
  const houseIds = queryResults.map(({ houseId }) => houseId);
  if (houseIds.length === 0) {
    return;
  }
  const housesDetails = yield call(fetchHouseUserDetails, {
    houseIds,
    businessContext,
  });
  const houses = housesDetails.map((house) => {
    const { property, owner } = house;
    return { ...property, owner };
  });
  const parsedQueryResults = queryResults.map(parseFetchHouseQuery(typedKey));
  const queryResultsWithHouses = parsedQueryResults.map((entity) => {
    const house = houses.find((property) => property.code === entity.houseId);
    return { ...house, entityId: entity[typedKey] };
  });
  const actionFetchHouseSuccess = getSuccessHouseActionType(type);
  yield all(
    queryResultsWithHouses.map((house) => put(actionFetchHouseSuccess(house))),
  );
}

export const fetchHouseDataBuilder = (type) => (action) =>
  fetchHouseData(action, type);

/**
 * Map all sagas used in app container
 * @returns {IterableIterator<*>}
 */
function* appSaga() {
  yield all([
    takeLatest(LOGIN_SUCCESS, authFlowSaga),
    takeLatest(REFRESH_PROFILE, refreshProfileSaga),
    takeLatest(FIREBASE_AUTH_STATE_CHANGED, firebaseAuthStateSaga),
    takeLatest(OFFER_LINK_REQUEST, sendOfferLinkRequestSaga),
    takeLatest(LOGOUT, firebaseLogoutSaga),
    takeLatest(FETCH_CONTRACTS_SUCCESS, fetchHouseDataBuilder(CONTRACT)),
    takeLatest(FETCH_CONTRACTS_SUCCESS, fetchUserDataBuilder(CONTRACT)),
    takeLatest(FETCH_OFFERS_SUCCESS, fetchHouseDataBuilder(OFFER)),
    takeLatest(FETCH_OFFERS_SUCCESS, fetchUserDataBuilder(OFFER)),
    takeLatest(FETCH_VISITS_SUCCESS, fetchHouseDataBuilder(VISIT)),
    takeLatest(FETCH_VISITS_SUCCESS, fetchUserDataBuilder(VISIT)),
    takeLatest(
      FETCH_DOCUMENTATIONS_SUCCESS,
      fetchHouseDataBuilder(DOCUMENTATION),
    ),
    takeLatest(
      FETCH_DOCUMENTATIONS_SUCCESS,
      fetchUserDataBuilder(DOCUMENTATION),
    ),
    takeLatest(FETCH_AGENT_RATING, fetchAgentRatingSaga),
  ]);
}

export default appSaga;
