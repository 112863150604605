export const FETCH_AGENT_SUPPORT = 'agentsPwa/AgentSupport/FETCH_AGENT_SUPPORT';
export const FETCH_AGENT_SUPPORT_BY_TENANT_ID =
  'agentsPwa/AgentSupport/FETCH_AGENT_SUPPORT_BY_TENANT_ID';
export const CLEAR_AGENT_SUPPORT = 'agentsPwa/AgentSupport/CLEAR_AGENT_SUPPORT';
export const SELECT_AGENT_SUPPORT =
  'agentsPwa/AgentSupport/SELECT_AGENT_SUPPORT';
export const SELECT_AGENT_SUPPORT_LISTING =
  'agentsPwa/AgentSupport/SELECT_AGENT_SUPPORT_LISTING';
export const START_AGENT_SUPPORT = 'agentsPwa/AgentSupport/START_AGENT_SUPPORT';
export const LOG_AGENT_SUPPORT_NOT_FOUND =
  'agentsPwa/AgentSupport/LOG_AGENT_SUPPORT_NOT_FOUND';
export const AGENT_SUPPORT_DETAILS_VIEWED =
  'agentsPwa/AgentSupport/AGENT_SUPPORT_DETAILS_VIEWED';
export const AGENT_SUPPORT_LISTING_DETAILS_VIEWED =
  'agentsPwa/AgentSupport/AGENT_SUPPORT_LISTING_DETAILS_VIEWED';
export const FETCH_REGION_INFO = 'agentsPwa/AgentSupport/FETCH_REGION_INFO';

export const AGENT_SUPPORT_ERROR = Object.freeze({
  ALREADY_STARTED: 'ALREADY_STARTED',
  NOT_FOUND: 'NOT_FOUND',
  TRANSFERRED: 'TRANSFERRED',
});

export const TTA_QUESTION_TYPES = Object.freeze({
  ABOUT_THIS_LISTING: 'ABOUT_THIS_LISTING',
  OFFER: 'OFFER',
  SIMILAR_LISTINGS: 'SIMILAR_LISTINGS',
  SCHEDULE_VISITS: 'SCHEDULE_VISITS',
  QUINTOANDAR: 'QUINTOANDAR',
});

export const AGENT_SUPPORT_CREATED_BY_TYPES = Object.freeze({
  CUSTOMER_EXPERIENCE: 'CUSTOMER_EXPERIENCE',
  TENANT_PROSPECT: 'TENANT_PROSPECT',
});
