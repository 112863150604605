import { LIMIT_OF_DAYS, TIME_SINCE_OFFER_DRAFT } from 'config';

import { defineMessages } from 'react-intl';
import { getBrandName } from 'utils/i18n/brand';

export default defineMessages({
  header: {
    id: 'app.containers.RentProgress.Documentation.header',
    defaultMessage: `{documentations, plural,
      =0 {Nenhuma proposta em documentação}
      one {# proposta em documentação}
      other {# propostas em documentação}
    }`,
  },
  supportText: {
    id: 'app.containers.RentProgress.Documentation.supportText',
    defaultMessage: `{limitDays, plural,
      =0 {Iniciadas {today}.}
      one {Iniciadas {yesterday}.}
      other {Iniciadas nos últimos {limitDaysBold} {days}.}
    }`,
  },
  emptyStateTitle: {
    id: 'app.containers.RentProgress.Documentation.emptyStateTitle',
    defaultMessage:
      'Não há visitas com propostas em documentação nos últimos {limitOfDays} dias',
  },
  emptyStateSubtitle: {
    id: 'app.containers.RentProgress.Documentation.emptyStateSubtitle',
    defaultMessage:
      'Aqui você acompanha o andamento das propostas em documentação das visitas que realizou.',
  },
  emptyFiltersSubtitle: {
    id: 'app.containers.RentProgress.Documentation.emptyFiltersSubtitle',
    defaultMessage:
      'Altere os filtros aplicados para buscar suas propostas em documentação.',
  },
  loadMore: {
    id: 'app.containers.RentProgress.Documentation.loadMore',
    defaultMessage: 'Mais documentações',
  },
  // Status
  waitingDocumentations: {
    id: 'app.containers.RentProgress.Documentation.waitingDocumentations',
    defaultMessage: 'Aguardando envio de documentos',
  },
  waitingTenantDocumentation: {
    id: 'app.containers.RentProgress.Documentation.waitingTenantDocumentation',
    defaultMessage: 'Aguardando documentos do interessado',
  },
  waitingOwnerDocumentation: {
    id: 'app.containers.RentProgress.Documentation.waitingOwnerDocumentation',
    defaultMessage: 'Aguardando documentos do propriétario',
  },
  waitingResendDocumentations: {
    id: 'app.containers.RentProgress.Documentation.waitingResendDocumentations',
    defaultMessage: 'Aguardando reenvio de documentos',
  },
  documentationsInAnalysis: {
    id: 'app.containers.RentProgress.Documentation.documentationsInAnalysis',
    defaultMessage: 'Documentações em análise',
  },
  documentationsRefused: {
    id: 'app.containers.RentProgress.Documentation.documentationsRefused',
    defaultMessage: 'Documentações recusadas',
  },
  tenantDocumentationInAnalysisChip: {
    id: 'app.containers.RentProgress.Documentation.tenantDocumentationInAnalysisChip',
    defaultMessage: 'Documentos do interessado em análise',
  },
  ownerDocumentationInAnalysisChip: {
    id: 'app.containers.RentProgress.Documentation.ownerDocumentationInAnalysisChip',
    defaultMessage: 'Documentos do proprietário em análise',
  },
  waitingContract: {
    id: 'app.containers.RentProgress.Documentation.waitingContract',
    defaultMessage: 'Aguardando contrato',
  },
  ownerDeadlineDocumentation: {
    id: 'app.containers.RentProgress.Documentation.ownerDeadlineDocumentation',
    defaultMessage:
      'Proprietário tem até {date} às {hour}h para enviar a documentação',
  },
  ownerDeadlineDocumentationNoDate: {
    id: 'app.containers.RentProgress.Documentation.ownerDeadlineDocumentationNoDate',
    defaultMessage: 'Proprietário precisa enviar a documentação',
  },
  tenantDeadlineDocumentation: {
    id: 'app.containers.RentProgress.Documentation.tenantDeadlineDocumentation',
    defaultMessage:
      'Interessado tem até {date} às {hour}h para enviar a documentação',
  },
  tenantDeadlineDocumentationNoDate: {
    id: 'app.containers.RentProgress.Documentation.tenantDeadlineDocumentationNoDate',
    defaultMessage: 'Interessado precisa enviar a documentação',
  },
  tenantDeadlineResendDocumentation: {
    id: 'app.containers.RentProgress.Documentation.tenantDeadlineResendDocumentation',
    defaultMessage:
      'Interessado tem até {date} às {hour}h para reenviar a documentação',
  },
  tenantDeadlineResendDocumentationNoDate: {
    id: 'app.containers.RentProgress.Documentation.tenantDeadlineResendDocumentationNoDate',
    defaultMessage: 'Interessado precisa reenviar a documentação',
  },
  ownerDeadlineResendDocumentation: {
    id: 'app.containers.RentProgress.Documentation.ownerDeadlineResendDocumentation',
    defaultMessage:
      'Proprietário tem até {date} às {hour}h para reenviar a documentação',
  },
  ownerDeadlineResendDocumentationNoDate: {
    id: 'app.containers.RentProgress.Documentation.ownerDeadlineResendDocumentationNoDate',
    defaultMessage: 'Proprietário precisa reenviar a documentação',
  },
  tenantDocumentationInAnalysis: {
    id: 'app.containers.RentProgress.Documentation.tenantDocumentationInAnalysis',
    defaultMessage: 'Interessado com documentação em análise (3 dias úteis)',
  },
  ownerDocumentationInAnalysis: {
    id: 'app.containers.RentProgress.Documentation.ownerDocumentationInAnalysis',
    defaultMessage: 'Proprietário com documentação em análise (3 dias úteis)',
  },
  tenantApprovedDocumentation: {
    id: 'app.containers.RentProgress.Documentation.tenantApprovedDocumentation',
    defaultMessage: 'Interessado com documentação aprovada',
  },
  ownerApprovedDocumentation: {
    id: 'app.containers.RentProgress.Documentation.ownerApprovedDocumentation',
    defaultMessage: 'Proprietário com documentação aprovada',
  },
  tenantRejected: {
    id: 'app.containers.RentProgress.Documentation.tenantRejected',
    defaultMessage: 'Recusado na análise de crédito',
  },
  waitingForTenantToDecide: {
    id: 'app.containers.RentProgress.Documentation.waitingForTenantToDecide',
    defaultMessage: 'Aguardando decisão do interessado',
  },
  tenantWithMultipleDocs: {
    id: 'app.containers.RentProgress.Documentation.tenantWithMultipleDocs',
    defaultMessage: 'Interessado com documentação em mais de um imóvel',
  },
  // rejection reasons
  tenantDoesntAgreeWithValue: {
    id: 'app.components.RejectionReason.tenantDoesntAgreeWithValue',
    defaultMessage: 'Interessado não concorda com o valor do aluguel',
  },
  tenantDoesntAgreeWithConditions: {
    id: 'app.components.RejectionReason.tenantDoesntAgreeWithConditions',
    defaultMessage:
      'Interessado não concorda com as negociações propostas pelo proprietário',
  },
  tenantGaveUpApartment: {
    id: 'app.components.RejectionReason.tenantGaveUpApartment',
    defaultMessage: 'Interessado desistiu de alugar este imóvel',
  },
  tenantRentedAnother: {
    id: 'app.components.RejectionReason.tenantRentedAnother',
    defaultMessage: 'Interessado alugou outro imóvel',
  },
  tenantRentedAnotherOutside: {
    id: 'app.components.RejectionReason.tenantRentedAnotherOutside',
    defaultMessage: 'Interessado alugou outro imóvel fora do {companyName}',
    values: { companyName: getBrandName() },
  },
  tenantGaveUpRenting: {
    id: 'app.components.RejectionReason.tenantGaveUpRenting',
    defaultMessage: 'Interessado desistiu de alugar nesse momento',
  },
  tenantStalled: {
    id: 'app.components.RejectionReason.tenantStalled',
    defaultMessage: 'Interessado não respondeu dentro do prazo de 72h',
  },
  ownerDoesntAgreeWithValue: {
    id: 'app.components.RejectionReason.ownerDoesntAgreeWithValue',
    defaultMessage: 'Proprietário não concorda com o valor de aluguel sugerido',
  },
  ownerDoesntAgreeWithConditions: {
    id: 'app.components.RejectionReason.ownerDoesntAgreeWithConditions',
    defaultMessage:
      'Proprietário não concorda com as solicitações especiais do interessado',
  },
  ownerSoldApartment: {
    id: 'app.components.RejectionReason.ownerSoldApartment',
    defaultMessage: 'Proprietário decidiu vender o imóvel',
  },
  ownerRentedForAnotherTenantOutside: {
    id: 'app.components.RejectionReason.ownerRentedForAnotherTenantOutside',
    defaultMessage: 'Proprietário alugou fora do {companyName}',
    values: { companyName: getBrandName() },
  },
  ownerRentedForAnotherTenant5A: {
    id: 'app.components.RejectionReason.ownerRentedForAnotherTenant5A',
    defaultMessage: 'Proprietário alugou esse imóvel para outro interessado',
  },
  ownerGaveUpRenting: {
    id: 'app.components.RejectionReason.ownerGaveUpRenting',
    defaultMessage: 'Proprietário desistiu de alugar esse imóvel',
  },
  ownerStalled: {
    id: 'app.components.RejectionReason.ownerStalled',
    defaultMessage: 'Proprietário não respondeu dentro do prazo de 72h',
  },
  // Filters
  filtersSectionTitle: {
    id: 'app.containers.RentProgress.Documentation.filtersSectionTitle',
    defaultMessage: 'Visualizar documentações:',
  },
  filtersOptionToday: {
    id: 'app.containers.RentProgress.Documentation.filtersOptionToday',
    key: 'TODAY',
    defaultMessage: 'hoje',
  },
  filtersOptionLastXDays: {
    id: 'app.containers.RentProgress.Documentation.filtersOptionLastXDays',
    key: `LAST_${TIME_SINCE_OFFER_DRAFT}_DAYS`,
    defaultMessage: 'últimos {timeSinceOfferDraft} dias',
  },
  filtersOptionLimitOfDays: {
    id: 'app.containers.RentProgress.Documentation.filtersOptionLimitOfDays',
    key: `LAST_${LIMIT_OF_DAYS}_DAYS`,
    defaultMessage: 'últimos {limitOfDays} dias',
  },
  sortingSectionTitle: {
    id: 'app.containers.RentProgress.Documentation.sortingSectionTitle',
    defaultMessage: 'Ordenar por:',
  },
  sortingOptionRecent: {
    id: 'app.containers.RentProgress.Documentation.sortingOptionRecent',
    key: 'DESC',
    defaultMessage: 'mais recentes',
  },
  sortingOptionOlder: {
    id: 'app.containers.RentProgress.Documentation.sortingOptionOlder',
    key: 'ASC',
    defaultMessage: 'mais antigas',
  },
});
