import {
  getEventDispatcherHandlerInstance,
  getBrazeEventDispatcherInstance,
  getAmplitudeEventDispatcherInstance,
} from '@quintoandar/event-tracker';
import { detectLocale, getCountry } from '@quintoandar/js-intl';

import { isAgentRede } from 'containers/App/User/Data/useAgentData';
import { agentWorksWithSales, agentWorksWithRent } from 'utils/agents';
import handleAgendaActions from './handlers/handleAgendaActions';
import { handleAgentSupportActions } from './handlers/handleAgentSupportActions';
import handleAppActions from './handlers/handleAppActions';
import handleDoneVisitsActions from './handlers/handleDoneVisitsActions';
import handleEntryInfoActions from './handlers/handleEntryInfoActions';
import handleFollowUpActions from './handlers/handleFollowUpActions';
import handleMyClientsGroupedActions from './handlers/handleMyClientsGroupedActions';
import handleOngoingNegotiationActions from './handlers/handleOngoingNegotiationActions';
import handleOnlineSupportActions from './handlers/handleOnlineSupportActions';
import handleRentProgressActions from './handlers/handleRentProgressActions';
import handleRescheduleVisitAction from './handlers/handleRescheduleVisitAction';
import handleScheduleVisitAction from './handlers/handleScheduleVisitActions';
import handleSearchClientsActions from './handlers/handleSearchClientsActions';
import handleSnackbarActions from './handlers/handleSnackbarActions';
import handleTimeBlockAction from './handlers/handleTimeBlockActions';
import handleVisitScheduledActions from './handlers/handleVisitScheduledActions';
import handleVisitorInfoActions from './handlers/handleVisitorInfoActions';
import handleWhatsAppLinkActions from './handlers/handleWhatsAppLinkActions';
import handleVisitDetailsActions from './handlers/handleVisitDetailsActions';
import { userFetcher } from './userFetcher';
import handleOwnerContactActions from './handlers/handleOwnerContactActions';

const brazeEventDispatcher = getBrazeEventDispatcherInstance(userFetcher);
const amplitudeEventDispatcher =
  getAmplitudeEventDispatcherInstance(userFetcher);

const getEventDispatcherHandlers = () => {
  const handlers = {
    brazeEventDispatcher,
    amplitudeEventDispatcher,
  };

  return getEventDispatcherHandlerInstance(handlers);
};

// eslint-disable-next-line no-unused-vars
const eventTrackerMiddleware = (store) => (next) => (action) => {
  const eventDispatcherHandler = getEventDispatcherHandlers();
  eventDispatcherHandler.setDefaultUserProperties();

  const state = store.getState();

  const businessContext = state.getIn(['app', 'agentData', 'businessContext']);
  const isSaleAgent = businessContext
    ? agentWorksWithSales(businessContext)
    : false;
  const isRentAgent = businessContext
    ? agentWorksWithRent(businessContext)
    : true;
  const locale = detectLocale();
  const country = getCountry(locale);
  const redeAgent = isAgentRede();

  eventDispatcherHandler.setUserProperties({
    isSales3P: redeAgent,
    isSaleAgent,
    isRentAgent,
    locale,
    country,
    market: country,
  });

  handleAgendaActions(eventDispatcherHandler, action);
  handleAgentSupportActions(eventDispatcherHandler, action);
  handleEntryInfoActions(eventDispatcherHandler, action, state);
  handleMyClientsGroupedActions(eventDispatcherHandler, action);
  handleOngoingNegotiationActions(eventDispatcherHandler, action);
  handleOnlineSupportActions(eventDispatcherHandler, action);
  handleRentProgressActions(eventDispatcherHandler, action);
  handleDoneVisitsActions(eventDispatcherHandler, action);
  handleFollowUpActions(eventDispatcherHandler, action, state);
  handleRescheduleVisitAction(eventDispatcherHandler, action, state);
  handleScheduleVisitAction(eventDispatcherHandler, action, state);
  handleTimeBlockAction(eventDispatcherHandler, action, state);
  handleAppActions(eventDispatcherHandler, action);
  handleVisitorInfoActions(eventDispatcherHandler, action);
  handleSnackbarActions(eventDispatcherHandler, action);
  handleWhatsAppLinkActions(eventDispatcherHandler, action, state);
  handleVisitScheduledActions(eventDispatcherHandler, action, state);
  handleSearchClientsActions(eventDispatcherHandler, action, state);
  handleVisitDetailsActions(eventDispatcherHandler, action);
  handleOwnerContactActions(eventDispatcherHandler, action);

  return next(action);
};

export default eventTrackerMiddleware;
