// These are the pages you can go to.
// They are all wrapped in the App component, which should contain the navbar etc
// See http://blog.mxstbr.com/2016/01/react-apps-with-pages for more information
// about the code splitting business
import NotFoundContainer from 'components/NotFoundPage/Loadable';
import { hasAuthCookie } from '@quintoandar/wat-cookie';
import { logout } from '@quintoandar/bioma-auth/containers/actions';
import AgentLeadReferralContainer from 'containers/AgentLeadReferral/Loadable';
import AgentSupportDetailContainer from 'containers/Agenda/Detail/AgentSupportDetail/Loadable';
import VisitDetailContainer from 'containers/Agenda/Detail/VisitDetail/Loadable';
import AgendaContainer from 'containers/Agenda/Loadable';
import AgentKeysContainer from 'containers/AgentKeys/Loadable';
import DoneVisits from 'containers/DoneVisits/Loadable';
import EntryInformationContainer from 'containers/EntryInformation/Loadable';
import ForbiddenContainer from 'containers/Forbidden';
import GetKeyFormContainer from 'containers/GetKeyForm/Loadable';
import MyClientsGroupedContainer from 'containers/MyClientsGrouped/Loadable';
import OngoingNegotiationContainer from 'containers/OngoingNegotiation/Loadable';
import OnlineSupportContainer from 'containers/OnlineSupport/Loadable';
import RentProgressContainer from 'containers/RentProgress/Loadable';
import ReturnKeyFormContainer from 'containers/ReturnKeyForm/Loadable';
import SearchClientsContainer from 'containers/SearchClients/Loadable';
import CalendarHoursBlockContainer from 'containers/CalendarHoursBlock/Loadable';
import VisitScheduledContainer from 'containers/VisitScheduled/Loadable';
import ClientsCrmContainer from 'containers/ClientsCrm/Loadable';
import ClientProfileContainer from 'Visitor/ClientProfile/Pages/Loadable';
import VisitorInfoContainer from 'Visitor/VisitorInfo/Pages/Loadable';
import AgentActivity from 'containers/AgentActivity/Page/Loadable';
import OffersPage from 'Offer/OffersPage/Page/Loadable';

import ROUTES from 'routes/constants';
import createLoginRoutes from 'routes/login';
import Redirecting from 'containers/Redirecting';
import { COOKIE_DOMAIN } from 'config';

export default function createRoutes(store) {
  return [
    ...createLoginRoutes(),
    {
      name: 'onlyForLoggedUsers',
      childRoutes: [
        {
          path: ROUTES.BASE_PATH,
          name: 'home',
          component: AgendaContainer,
        },
        {
          path: ROUTES.DONE_VISITS_PATH,
          name: 'done-visits',
          component: DoneVisits,
        },
        {
          path: ROUTES.OFFER_PATH,
          name: 'offers',
          component: RentProgressContainer,
        },
        {
          path: ROUTES.OFFERS_PATH,
          name: 'offers',
          component: OffersPage,
        },
        {
          path: ROUTES.DOCUMENTATION_PATH,
          name: 'documentation',
          component: RentProgressContainer,
        },
        {
          path: ROUTES.CONTRACT_PATH,
          name: 'contracts',
          component: RentProgressContainer,
        },
        {
          path: ROUTES.AGENDA,
          name: 'agenda',
          component: AgendaContainer,
        },
        {
          path: ROUTES.AGENDA_VISIT_DETAIL,
          name: 'details',
          component: VisitDetailContainer,
        },
        {
          path: ROUTES.AGENDA_AGENT_SUPPORT_DETAIL,
          name: 'details-agent-support',
          component: AgentSupportDetailContainer,
        },
        {
          path: ROUTES.ENTRY_INFORMATION_PATH,
          name: 'entry-information',
          component: EntryInformationContainer,
        },
        {
          path: ROUTES.ENTRY_INFORMATION_RESULT_LIST_PATH,
          name: 'entry-information-result-list',
          component: EntryInformationContainer,
        },
        {
          path: ROUTES.TIME_BLOCK,
          name: 'time-block',
          component: CalendarHoursBlockContainer,
        },
        {
          path: ROUTES.CLIENT_PROFILE,
          name: 'client-profile',
          component: ClientProfileContainer,
        },
        {
          path: ROUTES.VISITOR_INFO,
          name: 'visitor-info',
          component: VisitorInfoContainer,
        },
        {
          path: ROUTES.RETURN_KEYS_PATH,
          name: 'return-keys-path',
          component: ReturnKeyFormContainer,
        },
        {
          path: ROUTES.MY_CLIENTS_GROUPED,
          name: 'my-clients-grouped',
          component: MyClientsGroupedContainer,
        },
        {
          path: ROUTES.ONGOING_NEGOTIATION,
          name: 'ongoing-negotiation',
          component: OngoingNegotiationContainer,
        },
        {
          path: ROUTES.GET_KEYS_PATH,
          name: 'get-keys-path',
          component: GetKeyFormContainer,
        },
        {
          path: ROUTES.AGENT_KEYS,
          name: 'agent-keys',
          component: AgentKeysContainer,
        },
        {
          path: ROUTES.VISIT_SCHEDULED_PATH,
          name: 'visit-scheduled',
          component: VisitScheduledContainer,
        },
        {
          path: ROUTES.TRAZ_QUEM_COMPRA,
          name: 'traz-quem-compra',
          component: AgentLeadReferralContainer,
        },
        {
          path: ROUTES.SEARCH_CLIENTS,
          name: 'search-clients',
          component: SearchClientsContainer,
        },
        {
          path: ROUTES.ONLINE_SUPPORT_PATH,
          name: 'online-support',
          component: OnlineSupportContainer,
        },
        {
          path: ROUTES.CLIENTS_CRM,
          name: 'clients-listing',
          component: ClientsCrmContainer,
        },
        {
          path: ROUTES.MY_ACTIVITY,
          name: 'my-activity',
          component: AgentActivity,
        },
      ],
      onEnter(nextState, replace) {
        if (!hasAuthCookie()) {
          store.dispatch(logout(COOKIE_DOMAIN));

          const { location } = nextState;
          const path = location.pathname + location.search;
          replace(`/auth/login?redirect=${path}`);
        }
      },
    },
    {
      path: ROUTES.REDIRECTING,
      name: 'redirecting',
      component: Redirecting,
    },
    {
      path: ROUTES.FORBIDDEN_PATH,
      name: 'forbidden',
      component: ForbiddenContainer,
    },
    {
      path: '*',
      name: 'notFound',
      component: NotFoundContainer,
    },
  ];
}
