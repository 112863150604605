import {
  RENT_PROGRESS_STATUS_PAGE_VIEWED,
  RENT_PROGRESS_STATUS_CONTRACT,
  RENT_PROGRESS_STATUS_CARD_OPENED,
} from '../constants';

import {
  CONTRACT_HOUSE_FETCH_SUCCESS,
  CONTRACT_USER_FETCH_SUCCESS,
  COUNT_CONTRACTS_FAILURE,
  COUNT_CONTRACTS_REQUEST,
  COUNT_CONTRACTS_SUCCESS,
  FETCH_CONTRACTS_FAILURE,
  FETCH_CONTRACTS_REQUEST,
  FETCH_CONTRACTS_SUCCESS,
  SHOULD_FETCH_CONTRACTS,
} from './constants';

export const countContractsRequest = ({ filters }) => ({
  type: COUNT_CONTRACTS_REQUEST,
  payload: {
    filters,
  },
});

export const countContractsFailure = (message) => ({
  type: COUNT_CONTRACTS_FAILURE,
  payload: {
    message,
  },
});

export const countContractsSuccess = (count) => ({
  type: COUNT_CONTRACTS_SUCCESS,
  payload: {
    count,
  },
});

export const fetchContractsRequest = ({
  userId,
  agentId,
  filters,
  lastItem,
  businessContext,
}) => ({
  type: FETCH_CONTRACTS_REQUEST,
  payload: {
    userId,
    agentId,
    filters,
    lastItem,
    businessContext,
  },
});

export const fetchContractsFailure = (message) => ({
  type: FETCH_CONTRACTS_FAILURE,
  payload: {
    message,
  },
});

export const fetchContractsSuccess = ({ data, agentId, businessContext }) => ({
  type: FETCH_CONTRACTS_SUCCESS,
  payload: {
    data,
    agentId,
    businessContext,
  },
});

export const shouldFetchContracts = (status) => ({
  type: SHOULD_FETCH_CONTRACTS,
  payload: {
    status,
  },
});

export const contractHouseFetchSuccess = (payload) => ({
  type: CONTRACT_HOUSE_FETCH_SUCCESS,
  payload,
});

export const contractUserFetchSuccess = (payload) => ({
  type: CONTRACT_USER_FETCH_SUCCESS,
  payload,
});

export const triggerContractPageViewed = () => ({
  type: RENT_PROGRESS_STATUS_PAGE_VIEWED,
  extraProperties: {
    rent_progress_status: RENT_PROGRESS_STATUS_CONTRACT,
  },
});

export const triggerContractStatusCardOpened = () => ({
  type: RENT_PROGRESS_STATUS_CARD_OPENED,
  extraProperties: {
    rent_progress_status: RENT_PROGRESS_STATUS_CONTRACT,
  },
});
