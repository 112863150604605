export const ADD_AGENT_SUPPORT =
  'agentsPwa/Agenda/VisitDetail/ADD_AGENT_SUPPORT';
export const FETCH_AGENT_SUPPORT =
  'agentsPwa/Agenda/VisitDetail/FETCH_AGENT_SUPPORT';
export const FETCH_HOUSE_DETAILS =
  'agentsPwa/Agenda/VisitDetail/FETCH_HOUSE_DETAILS';
export const FETCH_HOUSE_SUGGESTIONS =
  'agentsPwa/Agenda/VisitDetail/FETCH_HOUSE_SUGGESTIONS';
export const CLEAR_VISIT_DETAIL_STATE =
  'agentsPwa/Agenda/VisitDetail/CLEAR_VISIT_DETAIL_STATE';
export const FETCH_HOUSE_RENT_PROGRESS_COUNT =
  'agentsPwa/Agenda/VisitDetail/FETCH_HOUSE_RENT_PROGRESS_COUNT';
export const CLOSE_ENTRY_INFO_DIALOG =
  'agentsPwa/Agenda/VisitDetail/CLOSE_ENTRY_INFO_DIALOG';
export const FETCH_HOUSE_ENTRY_INFORMATION =
  'agentsPwa/Agenda/VisitDetail/FETCH_HOUSE_ENTRY_INFORMATION';
export const FETCH_RESERVATION_INFO =
  'agentsPwa/Agenda/VisitDetail/FETCH_RESERVATION_INFO';
export const FETCH_SALE_HOUSE_PERFORMANCE_DATA =
  'agentsPwa/Agenda/VisitDetail/FETCH_SALE_HOUSE_PERFORMANCE_DATA';

export const AGENDA_VISIT_DETAIL_PAGE_VIEWED_ANALYTICS =
  'agentsPwa/Agenda/VisitDetail/AGENDA_VISIT_DETAIL_PAGE_VIEWED_ANALYTICS';
export const AGENDA_VISIT_DETAIL_CHAT_WITH_AGENT_CLICKED_ANALYTICS =
  'agentsPwa/Agenda/VisitDetail/AGENDA_VISIT_DETAIL_CHAT_WITH_AGENT_CLICKED_ANALYTICS';
export const AGENDA_VISIT_DETAIL_CHAT_WITH_OWNER_CLICKED_ANALYTICS =
  'agentsPwa/Agenda/VisitDetail/AGENDA_VISIT_DETAIL_CHAT_WITH_OWNER_CLICKED_ANALYTICS';
export const AGENDA_VISIT_DETAIL_HOUSE_ID_COPIED_ANALYTICS =
  'agentsPwa/Agenda/VisitDetail/AGENDA_VISIT_DETAIL_HOUSE_ID_COPIED_ANALYTICS';
export const AGENDA_VISIT_DETAIL_LISTING_CLICKED_ANALYTICS =
  'agentsPwa/Agenda/VisitDetail/AGENDA_VISIT_DETAIL_LISTING_CLICKED_ANALYTICS';
export const AGENDA_VISIT_DETAIL_ACTION_CLICKED_ANALYTICS =
  'agentsPwa/Agenda/VisitDetail/AGENDA_VISIT_DETAIL_ACTION_CLICKED_ANALYTICS';
export const AGENDA_VISIT_DETAIL_SUGGESTION_VIEWED_ANALYTICS =
  'agentsPwa/Agenda/VisitDetail/AGENDA_VISIT_DETAIL_SUGGESTION_VIEWED_ANALYTICS';
export const AGENDA_VISIT_DETAIL_SUGGESTION_CLICKED_ANALYTICS =
  'agentsPwa/Agenda/VisitDetail/AGENDA_VISIT_DETAIL_SUGGESTION_CLICKED_ANALYTICS';
export const FETCH_VISIT_CHECKIN_STATUS =
  'agentsPwa/Agenda/VisitDetail/FETCH_VISIT_CHECKIN_STATUS';
export const VISIT_CARD_CLICKED =
  'agentsPwa/Agenda/VisitDetail/VISIT_CARD_CLICKED';
