import { fetchHouseEntryInformationDetails } from 'api/agents/house';
import { CONTRACT_FETCH_HOUSE_ENTRY_INFORMATION } from '../Documentations/constants';
import {
  RENT_PROGRESS_STATUS_PAGE_VIEWED,
  RENT_PROGRESS_STATUS_NEGOTIATION,
  RENT_PROGRESS_STATUS_CARD_OPENED,
} from './../constants';

import {
  COUNT_OFFERS_FAILURE,
  COUNT_OFFERS_REQUEST,
  COUNT_OFFERS_SUCCESS,
  FETCH_OFFERS_FAILURE,
  FETCH_OFFERS_REQUEST,
  FETCH_OFFERS_SUCCESS,
  OFFER_HOUSE_FETCH_SUCCESS,
  OFFER_USER_FETCH_SUCCESS,
  SHOULD_FETCH_OFFERS,
} from './constants';

export const countOffersRequest = ({ filters }) => ({
  type: COUNT_OFFERS_REQUEST,
  payload: {
    filters,
  },
});

export const countOffersFailure = (message) => ({
  type: COUNT_OFFERS_FAILURE,
  payload: {
    message,
  },
});

export const countOffersSuccess = (count) => ({
  type: COUNT_OFFERS_SUCCESS,
  payload: {
    count,
  },
});

export const fetchOffersRequest = ({
  agentId,
  filters,
  lastItem,
  businessContext,
}) => ({
  type: FETCH_OFFERS_REQUEST,
  payload: {
    agentId,
    filters,
    lastItem,
    businessContext,
  },
});

export const fetchOffersFailure = (message) => ({
  type: FETCH_OFFERS_FAILURE,
  payload: {
    message,
  },
});

export const fetchOffersSuccess = ({ data, agentId, businessContext }) => ({
  type: FETCH_OFFERS_SUCCESS,
  payload: {
    data,
    agentId,
    businessContext,
  },
});

export const shouldFetchOffers = (status) => ({
  type: SHOULD_FETCH_OFFERS,
  payload: {
    status,
  },
});

export const fetchHouseEntryInformation = ({
  agentId,
  houseId,
  businessContext,
}) => {
  const fields = ['type', 'owner', 'ciq', 'listings'];
  return {
    type: CONTRACT_FETCH_HOUSE_ENTRY_INFORMATION,
    promise: fetchHouseEntryInformationDetails({ agentId, houseId, fields }),
    meta: {
      houseId,
      businessContext,
    },
  };
};

export const offerHouseFetchSuccess = (payload) => ({
  type: OFFER_HOUSE_FETCH_SUCCESS,
  payload,
});

export const offerUserFetchSuccess = (payload) => ({
  type: OFFER_USER_FETCH_SUCCESS,
  payload,
});

export const triggerNegotiationPageViewed = () => ({
  type: RENT_PROGRESS_STATUS_PAGE_VIEWED,
  extraProperties: {
    rent_progress_status: RENT_PROGRESS_STATUS_NEGOTIATION,
  },
});

export const triggerNegotiationStatusCardOpened = () => ({
  type: RENT_PROGRESS_STATUS_CARD_OPENED,
  extraProperties: {
    rent_progress_status: RENT_PROGRESS_STATUS_NEGOTIATION,
  },
});
