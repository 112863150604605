export const AmplitudeWebMobileMode = {
  homescreen: 'pwa_homescreen',
  browser: 'pwa_browser',
};

export const Platform = {
  mobile: 'web_mobile',
  tablet: 'web_tablet',
  desktop: 'web_desktop',
};

export const PAGE_NAMES = Object.freeze({
  AGENDA: 'Agenda',
  AGENDA_DETALHES: 'AgendaDetalhes',
  DONE_VISITS: 'DoneVisits',
  ENTRY_INFO: 'EntryInformation',
  FOLLOW_UP: 'FollowUp',
  MENU: 'Menu',
  NEW_VISIT: 'NewVisit',
  ONLINE_SUPPORT: 'OnlineSupport',
  RENT_PROGRESS: 'RentProgress',
  RESCHEDULE: 'Reschedule',
  SNACKBAR: 'Snackbar',
  TIME_BLOCK: 'TimeBlock',
  VISITOR_INFO: 'VisitorInfo',
  MINHAS_CHAVES: 'MinhasChaves',
  MY_CLIENTS_GROUPED: 'MyCLientsGrouped',
  ONGOING_NEGOTIATION: 'OngoingNegotiation',
  VISIT_SCHEDULED: 'VisitScheduled',
  SEARCH_CLIENTS: 'SearchClients',
  VISIT_CONFIRMATION: 'VisitConfirmation',
  VISIT_CONFIRMATION_DETAIL: 'VisitConfirmationDetail',
  VISIT_CONFIRMED_SUCCESSFULLY: 'VisitConfirmedSuccessfully',
  REDIRECTING: 'Redirecting',
});
