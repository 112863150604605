import { REQUESTED_FIELDS } from 'api/providers/visitAPI/constants';
import { VISIT_STATUS_V3 } from 'enums/visitStatus';

export const TAB_HEIGHT = 48;
export const AGENT_SUPPORT_HEIGHT = 185;

export const DAYS_TO_DISPLAY = Object.freeze({
  SALE: 8,
  RENT: 8,
});

export const ACTION_TYPES = Object.freeze({
  CANCEL_BY_GROUP: 'CANCEL_BY_GROUP',
  CANCEL_VISITOR: 'CANCEL_VISITOR',
  FINISH_VISIT: 'FINISH_VISIT',
  SEND_INTRODUCTION: 'SEND_INTRODUCTION',
  SEND_OFFER: 'SEND_OFFER',
  RESCHEDULE_VISIT: 'RESCHEDULE_VISIT',
  START_VISIT: 'START_VISIT',
  OPEN_DETAILS_PAGE: 'OPEN_DETAILS_PAGE',
});

export const VISIT_FOLLOW_UP_BUTTON_TESTID = 'visit-follow-up-button';

export const SCHEDULE_TYPE = Object.freeze({
  VISIT: 'VISIT',
  TEMPORARY_BLOCK: 'TEMPORARY_BLOCK',
  FIXED_BLOCK: 'FIXED_BLOCK',
});

export const SCHEDULE_BLOCK_TYPES = Object.freeze([
  SCHEDULE_TYPE.TEMPORARY_BLOCK,
  SCHEDULE_TYPE.FIXED_BLOCK,
]);

export const FULLDAY_SCHEDULE = Object.freeze({
  startTime: '08:00',
  endTime: '20:00',
  isEntireDay: true,
});

export const SALE_ADMINISTRATOR = Object.freeze({
  STANDARD: 'STANDARD',
  THIRD_PARTY: 'THIRD_PARTY',
});

export const LISTING_BUSINESS_CONTEXT_FEATURE_FLAG = 'listing_business_context';

export const NEW_ATTENDANCE_FAB_BUTTON_TEST_ID = 'new-attendance-fab';

export const NEW_VISIT_DATETIME_WITH_WARNING_SELECTED =
  'new_visit_datetime_with_warning_selected';

export const UNPUBLISH_HOUSE_LISTING_1P_FEATURE_FLAG = 'unpublish_listing_1p';

export const SALE_BYPASS_VISIT_CHECKIN_FLAG = 'sale_bypass_visit_checkin_flag';

export const AGENTS_SECTION_ENGAGEMENT = 'agents_section_engagement';

export const FINISH_VISIT_FOR_RENT_FEATURE_FLAG = 'FINISH_VISIT_FOR_RENT';

export const IS_BIOMA_CANCELLATION_DIALOG_ENABLED =
  'isbiomacanceldialogenabled';

export const VISIT_DETAILS_PRICE_TABLE_RENTAL_GUARANTEE_ENABLED =
  'visit_details_price_table_rental_guarantee_enabled';

export const AGENDA_TRACK_EVENTS = Object.freeze({
  REFRESH_BUTTON_CLICKED: 'agenda_refresh_button_clicked',
  ERROR_REFRESH_BUTTON_CLICKED: 'agenda_error_refresh_button_clicked',
});

export const DEFAULT_PAGE_SIZE = 100;

export const DEFAULT_CURRENT_PAGE = 1;

export const DEFAULT_REQUESTED_FIELDS = Object.freeze([
  REQUESTED_FIELDS.ACTOR_DETAILS,
  REQUESTED_FIELDS.HOUSE_DETAILS,
  REQUESTED_FIELDS.STRUCTURED_STATUS,
  REQUESTED_FIELDS.FOLLOW_UP,
  REQUESTED_FIELDS.TEMP_MIGRATION_SUPPORT,
]);
export const DEFAULT_VISIT_STATUS = Object.freeze([
  VISIT_STATUS_V3.DONE,
  VISIT_STATUS_V3.CANCELED,
  VISIT_STATUS_V3.CONFIRMED,
  VISIT_STATUS_V3.REQUESTED,
  VISIT_STATUS_V3.REQUEST_CANCELED,
]);

export const VISIT_CONFIRMATION_FLOW = 'visitConfirmation';

export const AGENTS_FOR_HOTJAR_SURVEY = [
  12670, 12915, 19136, 18628, 18960, 4695, 8434, 9907, 14266, 17296, 37, 13674,
  19631, 18606, 6356, 9934, 9398, 7540, 19716, 16605, 6708, 9921, 18106, 9612,
  15149, 21020, 6228, 18636, 10373, 18042, 9617, 6339, 8678, 1802, 19328, 7606,
  19278, 19950, 15057, 17470, 8747, 17029, 10452, 18378, 18568, 9401, 12181,
  16761, 9622, 18143, 4082, 605, 6413, 152, 5406, 7818, 134, 16875, 12870, 2859,
  6410, 19032, 18678, 18465, 6358, 13219, 10984, 20091, 7472, 20247, 18850,
  17184, 8760, 7821, 9905, 14849, 8885, 17460, 17081, 19926, 19285, 6160, 8523,
  17023, 8710, 16600, 17674, 12499, 18567, 18459, 14841, 17621, 13700, 19919,
  19918, 19023, 14328, 14269, 5736, 19797, 19720, 3088, 16228, 19398, 20276,
  16521, 17457, 16219, 20259, 16920, 16445, 16229, 20196, 16233, 16446, 16483,
  20272, 16450, 16369, 16360, 16484, 16523, 16928, 16272, 16937, 16528, 14107,
  16357, 16881, 16180, 9580, 3068, 17840, 19204, 16189, 10675, 9954, 13112,
  13728, 15636, 11058, 12676, 20145, 17198, 18607, 8771, 6184, 19527, 14918,
  10719, 19914, 19521, 10350, 19717, 19518, 17082, 14612, 11450, 7381, 19135,
  20270, 19370, 11766, 13266, 1815, 9420, 4618, 12359, 8960, 15198, 18, 19034,
  19160, 17183, 791, 15226, 17848, 3473, 19886, 20529, 18786, 14275, 8852,
  19031, 11184, 20910, 13270, 9933, 19545, 11837, 18956, 6509, 6117, 7194,
  19008, 16651, 9568, 9572, 15466, 6439, 7384, 17294, 11513, 11453, 19507,
  19629, 9912, 7828, 19228, 3334, 6360, 9402, 20911, 18977, 6308, 9894, 19105,
  81, 10443, 18533, 17394, 2508, 8746, 1424, 12432, 17402, 850, 19439, 13435,
  18853, 8959, 17712, 14796, 15189, 17582, 16680, 12264, 12055, 7635, 9049,
  6815, 19718, 12208, 8441, 19732, 18467, 19070, 16188, 16250, 16309, 16169,
  17340, 19659, 20049, 16255, 16941, 16526, 16195, 3198, 17737, 16257, 16150,
  16506, 19952, 16929, 16200, 17838, 16359, 3154, 16455, 16448, 12875, 3405,
  19074, 16464, 16193, 16210, 16447, 16205, 17376, 16298, 16499, 17557, 9297,
  16588, 16510, 12990, 14656, 2822, 18918, 2527, 13154, 13110, 11400, 11822,
  10807, 13108, 10125, 9646, 18416, 19276, 14752, 19033, 300, 865, 9519, 9623,
  14259, 15286, 14836, 19792, 15372, 19224, 18102, 9549, 17316, 19921, 14992,
  18489, 20565, 17471, 21018, 1257, 20260, 1426, 20269, 1486, 17467, 8853,
  17853, 19271, 7400, 12370, 19698, 17974, 9647, 20294, 518, 18376, 6711, 7551,
  12656, 17711, 11444, 13262, 6177, 19517, 15143, 17348, 19530, 15046, 9451,
  19638, 19524, 18755, 18629, 19597, 19159, 18637, 14884, 18085, 494, 6364,
  14323, 18848, 19060, 12615, 15028, 13632, 17209, 7903, 2922, 4938, 16353,
  3421, 14271, 1249, 6432, 11923, 16279, 2472, 3283, 14480, 18123, 6950, 6902,
  16381, 16208, 17343, 19809, 16245, 16392, 16477, 16476, 16472, 16225, 16500,
  16249, 16412, 16495, 17069, 16458, 19803, 8513, 19662, 16246, 16946, 16503,
  16435, 16313, 3533, 16261, 18476, 16237, 4763, 3851, 12084, 6152, 15705,
  17028, 17077, 17361, 10808, 7853, 19794, 19637, 21019, 10360, 6855, 18529,
  7438, 10312, 12810, 14474, 17849, 6243, 17208, 18098, 18382, 12897, 14843,
  18523, 2697, 5149, 12518, 15058, 2049, 19538, 14962, 10493, 19793, 5830,
  12198, 10280, 12594, 16873, 16829, 10742, 18683, 3463, 11875, 12308, 18469,
  10615, 15379, 11584, 14306, 11103, 19253, 20333, 10357, 6406, 19630, 17999,
  17024, 520, 10310, 19499, 5693, 16874, 7465, 8178, 17084, 10347, 9076, 18453,
  18756, 4662, 9920, 19791, 18487, 18971, 11268, 873, 17464, 31, 19001, 11161,
  14842, 8736, 2110, 12199, 11782, 19107, 19038, 5170, 17186, 16533, 19492,
  6861, 19636, 10989, 17312, 16703, 13166, 20929, 11066, 17332, 17061, 16912,
  2968, 16428, 6759, 16531, 16915, 16471, 6661, 20248, 16301, 16227, 16897,
  16254, 17395, 16427, 16277, 16144, 3809, 17726, 19073, 19808, 16388, 14653,
  13111, 11924, 10519, 14325, 17618, 13152, 1701, 19279, 15073, 15107, 1623,
  15564, 17834, 10996, 19061, 8989, 15053, 16596, 9391, 4984, 10628, 19078,
  16363, 19324, 27, 7910, 18514, 18938, 1142, 19104, 7321, 6354, 17, 13015,
  18948, 18522, 6772, 17313, 17996, 10358, 20558, 24, 9381, 20009, 17538, 18784,
  17540, 7007, 10040, 270, 17255, 13163, 17134, 10311, 14400, 9491, 14315,
  10309, 17855, 17537, 18953, 14900, 18488, 17310, 14608, 18069, 19401, 17083,
  1615, 779, 6440, 5588, 18783, 17253, 7676, 14399, 16801, 8969, 14904, 19215,
  19326, 16668, 16704, 11448, 14308, 19721, 5434, 9143, 8895, 1099, 2305, 13208,
  17187, 16307, 19801, 4186, 16416, 16578, 16234, 12100, 16426, 3167, 16415,
  17342, 16316, 19331, 16258, 19075, 16444, 16576, 16519, 16393, 4064, 16400,
  14735,
];
