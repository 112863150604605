import { HTTP_METHODS } from 'config';

import { buildFieldsQueryParams } from '../../utils/urls';
import { USERS } from './endpoints';

import agentsApiConfig from './index';

export const fetchUsersDetails = ({ usersId }) =>
  agentsApiConfig({
    endpoint: USERS,
    method: HTTP_METHODS.GET,
    queryString: buildFieldsQueryParams('id', usersId),
  });

export const fetchAgentDetails = ({ agentIds }) =>
  agentsApiConfig({
    endpoint: `${USERS}/agent-details`,
    method: HTTP_METHODS.GET,
    queryString: `agentId=${agentIds}`,
  });

export const fetchUsersWithSearchFilter = ({ searchFilter, country }) =>
  agentsApiConfig({
    endpoint: `${USERS}/search`,
    method: HTTP_METHODS.GET,
    queryString: `searchFilter=${searchFilter}&country=${country}`,
  });
