export const COUNT_OFFERS_FAILURE = 'agentsPwa/Offers/COUNT_OFFERS_FAILURE';
export const COUNT_OFFERS_REQUEST = 'agentsPwa/Offers/COUNT_OFFERS_REQUEST';
export const COUNT_OFFERS_SUCCESS = 'agentsPwa/Offers/COUNT_OFFERS_SUCCESS';
export const FETCH_OFFERS_FAILURE = 'agentsPwa/Offers/FETCH_OFFERS_FAILURE';
export const FETCH_OFFERS_REQUEST = 'agentsPwa/Offers/FETCH_OFFERS_REQUEST';
export const FETCH_OFFERS_SUCCESS = 'agentsPwa/Offers/FETCH_OFFERS_SUCCESS';
export const SHOULD_FETCH_OFFERS = 'agentsPwa/Offers/SHOULD_FETCH_OFFERS';
export const OFFER_HOUSE_FETCH_SUCCESS =
  'agentsPwa/Offers/OFFER_HOUSE_FETCH_SUCCESS';
export const OFFER_USER_FETCH_SUCCESS =
  'agentsPwa/Offers/OFFER_USER_FETCH_SUCCESS';
