export const COUNT_CONTRACTS_FAILURE =
  'agentsPwa/Contracts/COUNT_CONTRACTS_FAILURE';
export const COUNT_CONTRACTS_REQUEST =
  'agentsPwa/Contracts/COUNT_CONTRACTS_REQUEST';
export const COUNT_CONTRACTS_SUCCESS =
  'agentsPwa/Contracts/COUNT_CONTRACTS_SUCCESS';
export const FETCH_CONTRACTS_FAILURE =
  'agentsPwa/Contracts/FETCH_CONTRACTS_FAILURE';
export const FETCH_CONTRACTS_REQUEST =
  'agentsPwa/Contracts/FETCH_CONTRACTS_REQUEST';
export const FETCH_CONTRACTS_SUCCESS =
  'agentsPwa/Contracts/FETCH_CONTRACTS_SUCCESS';
export const SHOULD_FETCH_CONTRACTS =
  'agentsPwa/Contracts/SHOULD_FETCH_CONTRACTS';
export const CONTRACT_HOUSE_FETCH_SUCCESS =
  'agentsPwa/Contracts/CONTRACT_HOUSE_FETCH_SUCCESS ';
export const CONTRACT_USER_FETCH_SUCCESS =
  'agentsPwa/Contracts/CONTRACT_USER_FETCH_SUCCESS ';
