import { LIFECYCLE, KEY } from 'redux-pack';
import {
  AGENT_SUPPORT_CREATED_BY_TYPES,
  AGENT_SUPPORT_DETAILS_VIEWED,
  LOG_AGENT_SUPPORT_NOT_FOUND,
  SELECT_AGENT_SUPPORT,
  SELECT_AGENT_SUPPORT_LISTING,
  START_AGENT_SUPPORT,
  AGENT_SUPPORT_LISTING_DETAILS_VIEWED,
} from 'containers/AgentSupport/constants';
import { PAGE_NAMES } from '../constants';
import { immutablePage } from '../utils';

const INVALID_STATUS_UPDATE_CODE_MIN = 400;
const INVALID_STATUS_UPDATE_CODE_MAX = 500;

const AGENT_SUPPORT_LOG_TYPES = Object.freeze({
  [AGENT_SUPPORT_CREATED_BY_TYPES.CUSTOMER_EXPERIENCE]:
    'tta_detail_start_online_service_clicked',
  [AGENT_SUPPORT_CREATED_BY_TYPES.TENANT_PROSPECT]:
    'tta_listing_detail_start_online_service_clicked',
});

export const handleAgentSupportActions = (eventDispatcherHandler, action) => {
  const failureFromSameStatusChange = () => {
    const response = action.payload?.response;
    return (
      action.error &&
      action.meta[KEY.LIFECYCLE] === LIFECYCLE.FAILURE &&
      response?.status >= INVALID_STATUS_UPDATE_CODE_MIN &&
      response?.status < INVALID_STATUS_UPDATE_CODE_MAX
    );
  };

  switch (action.type) {
    case LOG_AGENT_SUPPORT_NOT_FOUND:
      eventDispatcherHandler.logWithDefaultEventProps(
        'agent_support_not_found',
        immutablePage(PAGE_NAMES.AGENDA),
        {
          agentSupportId: action.extraProperties.supportId,
          loggedAgentId: action.extraProperties.agentId,
          channel: action.extraProperties.channel,
        },
      );
      break;

    case START_AGENT_SUPPORT:
      if (action.meta[KEY.LIFECYCLE] === LIFECYCLE.START) {
        eventDispatcherHandler.logWithDefaultEventProps(
          AGENT_SUPPORT_LOG_TYPES[action.meta.createdBy],
          immutablePage(PAGE_NAMES.AGENDA),
          {
            channel: action.meta.channel,
          },
        );
      } else if (failureFromSameStatusChange()) {
        eventDispatcherHandler.logWithDefaultEventProps(
          'agent_support_update_status_error',
          immutablePage(PAGE_NAMES.AGENDA),
          {
            agentSupportId: action.meta.supportId,
            loggedAgentId: action.meta.agentId,
            currentStatus: action.meta.currentStatus,
            newStatus: action.meta.newStatus,
            channel: action.meta.channel,
          },
        );
      }
      break;

    case SELECT_AGENT_SUPPORT:
      if (action.payload) {
        eventDispatcherHandler.logWithDefaultEventProps(
          'tta_agenda_start_online_service_clicked',
          immutablePage(PAGE_NAMES.AGENDA),
        );
      }
      break;

    case AGENT_SUPPORT_DETAILS_VIEWED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'tta_detail_page_viewed',
        immutablePage(PAGE_NAMES.AGENDA),
      );
      break;

    case SELECT_AGENT_SUPPORT_LISTING:
      if (action.payload) {
        eventDispatcherHandler.logWithDefaultEventProps(
          'tta_listing_detail_start_online_service_clicked',
          immutablePage(PAGE_NAMES.AGENDA),
        );
      }
      break;

    case AGENT_SUPPORT_LISTING_DETAILS_VIEWED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'tta_listing_details_viewed',
        immutablePage(PAGE_NAMES.AGENDA),
      );
      break;

    default:
      break;
  }
};
