import { LIMIT_OF_DAYS, TIME_SINCE_OFFER_DRAFT } from 'config';

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.RentProgress.Offers.header',
    defaultMessage: `{offers, plural,
      =0 {Nenhuma proposta em negociação}
      one {# proposta em negociação}
      other {# propostas em negociação}
    }`,
  },
  supportText: {
    id: 'app.containers.RentProgress.Offers.supportText',
    defaultMessage: `{limitDays, plural,
      =0 {Iniciadas {today}.}
      one {Iniciadas {yesterday}.}
      other {Iniciadas nos últimos {limitDaysBold} {days}}
    }`,
  },
  emptyStateTitle: {
    id: 'app.containers.RentProgress.Offers.emptyStateTitle',
    defaultMessage:
      'Não há visitas com propostas em negociação nos últimos {limitOfDays} dias',
  },
  emptyStateSubtitle: {
    id: 'app.containers.RentProgress.Offers.emptyStateSubtitle',
    defaultMessage:
      'Aqui você acompanha o andamento das propostas em negociação das visitas que realizou.',
  },
  emptyFiltersSubtitle: {
    id: 'app.containers.RentProgress.Offers.emptyFiltersSubtitle',
    defaultMessage:
      'Altere os filtros aplicados para buscar suas propostas em negociação.',
  },
  loadMore: {
    id: 'app.containers.RentProgress.Offers.loadMore',
    defaultMessage: 'Mais negociações',
  },
  // Status
  tenantDidntSentOffer: {
    id: 'app.containers.RentProgress.Offers.tenantDidntSentOffer',
    defaultMessage: 'Interessado não finalizou envio da proposta',
  },
  tenantDidntSentOfferTenantStatus: {
    id: 'app.containers.RentProgress.Offers.tenantDidntSentOfferTenantStatus',
    defaultMessage:
      'Interessado deu início a proposta mas falta finalizar o envio',
  },
  waitingOwnerResponse: {
    id: 'app.containers.RentProgress.Offers.waitingOwnerResponse',
    defaultMessage: 'Aguardando resposta do proprietário',
  },
  waitingOwnerResponseTenantStatus: {
    id: 'app.containers.RentProgress.Offers.waitingOwnerResponseTenantStatus',
    defaultMessage: 'Interessado enviou a proposta',
  },
  waitingOwnerResponseOwnerStatus: {
    id: 'app.containers.RentProgress.Offers.waitingOwnerResponseOwnerStatus',
    defaultMessage:
      'Proprietário tem até {date} às {hour} para responder a proposta',
  },
  waitingOwnerResponseOwnerStatusNoDate: {
    id: 'app.containers.RentProgress.Offers.waitingOwnerResponseOwnerStatusNoDate',
    defaultMessage: 'Proprietário precisa responder a proposta',
  },
  waitingTenantRespondCounterOffer: {
    id: 'app.containers.RentProgress.Offers.waitingTenantRespondCounterOffer',
    defaultMessage: 'Contra-proposta feita pelo proprietário',
  },
  waitingTenantRespondCounterOfferTenantStatus: {
    id: 'app.containers.RentProgress.Offers.waitingTenantRespondCounterOfferTenantStatus',
    defaultMessage:
      'Interessado tem até {date} às {hour} para responder a contra-proposta',
  },
  waitingTenantRespondCounterOfferTenantStatusNoDate: {
    id: 'app.containers.RentProgress.Offers.waitingTenantRespondCounterOfferTenantStatusNoDate',
    defaultMessage: 'Interessado precisa responder a contra-proposta',
  },
  waitingTenantRespondCounterOfferOwnerStatus: {
    id: 'app.containers.RentProgress.Offers.waitingTenantRespondCounterOfferOwnerStatus',
    defaultMessage: 'Proprietário enviou uma contra-proposta',
  },
  waitingOwnerRespondCounterOffer: {
    id: 'app.containers.RentProgress.Offers.waitingOwnerRespondCounterOffer',
    defaultMessage: 'Contra-proposta feita pelo interessado',
  },
  waitingOwnerRespondCounterOfferTenantStatus: {
    id: 'app.containers.RentProgress.Offers.waitingOwnerRespondCounterOfferTenantStatus',
    defaultMessage: 'Interessado enviou uma contra-proposta',
  },
  waitingOwnerRespondCounterOfferOwnerStatus: {
    id: 'app.containers.RentProgress.Offers.waitingOwnerRespondCounterOfferOwnerStatus',
    defaultMessage:
      'Proprietário tem até {date} às {hour} para responder a contra-proposta',
  },
  waitingOwnerRespondCounterOfferOwnerStatusNoDate: {
    id: 'app.containers.RentProgress.Offers.waitingOwnerRespondCounterOfferOwnerStatusNoDate',
    defaultMessage: 'Proprietário precisa responder a contra-proposta',
  },
  ownerRejectedOffer: {
    id: 'app.containers.RentProgress.Offers.ownerRejectedOffer',
    defaultMessage: 'Proposta rejeitada',
  },
  ownerRejectedOfferOwnerStatus: {
    id: 'app.containers.RentProgress.Offers.ownerRejectedOfferOwnerStatus',
    defaultMessage: 'Proposta rejeitada por: "{rejectionReason}"',
  },
  tenantRejectedOffer: {
    id: 'app.containers.RentProgress.Offers.tenantRejectedOffer',
    defaultMessage: 'Proposta rejeitada',
  },
  tenantRejectedOfferTenantStatus: {
    id: 'app.containers.RentProgress.Offers.tenantRejectedOfferTenantStatus',
    defaultMessage: 'Proposta rejeitada por: "{rejectionReason}"',
  },
  waitingDocumentations: {
    id: 'app.containers.RentProgress.Offers.waitingDocumentations',
    defaultMessage: 'Aguardando envio de ambas documentações',
  },
  waitingTenantDocumentation: {
    id: 'app.containers.RentProgress.Offers.waitingTenantDocumentation',
    defaultMessage: 'Aguardando interessado enviar documentação',
  },
  waitingOwnerDocumentation: {
    id: 'app.containers.RentProgress.Offers.waitingOwnerDocumentation',
    defaultMessage: 'Aguardando proprietário enviar documentação',
  },
  waitingResendDocumentations: {
    id: 'app.containers.RentProgress.Offers.waitingResendDocumentations',
    defaultMessage: 'Aguardando reenvio de ambas documentações',
  },
  waitingTenantResendDocumentation: {
    id: 'app.containers.RentProgress.Offers.waitingTenantResendDocumentation',
    defaultMessage: 'Aguardando interessado reenviar documentação',
  },
  waitingOwnerResendDocumentation: {
    id: 'app.containers.RentProgress.Offers.waitingOwnerResendDocumentation',
    defaultMessage: 'Aguardando proprietário reenviar documentação',
  },
  documentationsInAnalysis: {
    id: 'app.containers.RentProgress.Offers.documentationsInAnalysis',
    defaultMessage: 'Documentações em análise',
  },
  documentationsRefused: {
    id: 'app.containers.RentProgress.Offers.documentationsRefused',
    defaultMessage: 'Documentações recusadas',
  },
  tenantDocumentationInAnalysisChip: {
    id: 'app.containers.RentProgress.Offers.tenantDocumentationInAnalysisChip',
    defaultMessage: 'Documentação interessado em análise',
  },
  ownerDocumentationInAnalysisChip: {
    id: 'app.containers.RentProgress.Offers.ownerDocumentationInAnalysisChip',
    defaultMessage: 'Documentação proprietário em análise ',
  },
  waitingContract: {
    id: 'app.containers.RentProgress.Offers.waitingContract',
    defaultMessage: 'Aguardando contrato',
  },
  owner: {
    id: 'app.containers.RentProgress.Offers.waitingOwnerResendDocumentation',
    defaultMessage: 'Aguardando proprietário reenviar documentação',
  },
  ownerDeadlineDocumentation: {
    id: 'app.containers.RentProgress.Offers.ownerDeadlineDocumentation',
    defaultMessage:
      'Proprietário tem até {date} às {hour}h para enviar a documentação',
  },
  ownerDeadlineDocumentationNoDate: {
    id: 'app.containers.RentProgress.Offers.ownerDeadlineDocumentationNoDate',
    defaultMessage: 'Proprietário precisa enviar a documentação',
  },
  tenantDeadlineDocumentation: {
    id: 'app.containers.RentProgress.Offers.tenantDeadlineDocumentation',
    defaultMessage:
      'Interessado tem até {date} às {hour}h para enviar a documentação',
  },
  tenantDeadlineDocumentationNoDate: {
    id: 'app.containers.RentProgress.Offers.tenantDeadlineDocumentationNoDate',
    defaultMessage: 'Interessado precisa enviar a documentação',
  },
  tenantDeadlineResendDocumentation: {
    id: 'app.containers.RentProgress.Offers.tenantDeadlineResendDocumentation',
    defaultMessage:
      'Interessado tem até {date} às {hour}h para reenviar a documentação',
  },
  tenantDeadlineResendDocumentationNoDate: {
    id: 'app.containers.RentProgress.Offers.tenantDeadlineResendDocumentationNoDate',
    defaultMessage: 'Interessado precisa reenviar a documentação',
  },
  ownerDeadlineResendDocumentation: {
    id: 'app.containers.RentProgress.Offers.ownerDeadlineResendDocumentation',
    defaultMessage:
      'Proprietário tem até {date} às {hour}h para reenviar a documentação',
  },
  ownerDeadlineResendDocumentationNoDate: {
    id: 'app.containers.RentProgress.Offers.ownerDeadlineResendDocumentationNoDate',
    defaultMessage: 'Proprietário precisa reenviar a documentação',
  },
  tenantDocumentationInAnalysis: {
    id: 'app.containers.RentProgress.Offers.tenantDocumentationInAnalysis',
    defaultMessage: 'Interessado com documentação em análise (3 dias úteis)',
  },
  ownerDocumentationInAnalysis: {
    id: 'app.containers.RentProgress.Offers.ownerDocumentationInAnalysis',
    defaultMessage: 'Proprietário com documentação em análise (3 dias úteis)',
  },
  tenantApprovedDocumentation: {
    id: 'app.containers.RentProgress.Offers.tenantApprovedDocumentation',
    defaultMessage: 'Interessado com documentação aprovada',
  },
  ownerApprovedDocumentation: {
    id: 'app.containers.RentProgress.Offers.ownerApprovedDocumentation',
    defaultMessage: 'Proprietário com documentação aprovada',
  },
  tenantRejected: {
    id: 'app.containers.RentProgress.Offers.tenantRejected',
    defaultMessage: 'Interessado recusado na análise de crédito',
  },
  proposalCancelled: {
    id: 'app.containers.RentProgress.Offers.proposalCancelled',
    defaultMessage: 'Proposta cancelada',
  },
  waitingTenantSignContractTenantStatus: {
    id: 'app.containers.RentProgress.Offers.waitingTenantSignContractTenantStatus',
    defaultMessage:
      'Interessado tem até {date} às {hour}h para assinatura do contrato',
  },
  waitingForTenantToDecide: {
    id: 'app.containers.RentProgress.Offers.waitingForTenantToDecide',
    defaultMessage: 'Aguardando interessado decidir sobre imóvel',
  },
  tenantWithMultipleDocs: {
    id: 'app.containers.RentProgress.Offers.tenantWithMultipleDocs',
    defaultMessage: 'Interessado com documentação em mais de um imóvel',
  },
  // rejection reasons
  tenantDoesntAgreeWithValue: {
    id: 'app.components.RejectionReason.tenantDoesntAgreeWithValue',
    defaultMessage: 'Interessado não concorda com o valor do aluguel',
  },
  tenantDoesntAgreeWithConditions: {
    id: 'app.components.RejectionReason.tenantDoesntAgreeWithConditions',
    defaultMessage:
      'Interessado não concorda com as negociações propostas pelo proprietário',
  },
  tenantGaveUpApartment: {
    id: 'app.components.RejectionReason.tenantGaveUpApartment',
    defaultMessage: 'Interessado desistiu de alugar este imóvel',
  },
  tenantRentedAnother: {
    id: 'app.components.RejectionReason.tenantRentedAnother',
    defaultMessage: 'Interessado alugou outro imóvel',
  },
  tenantRentedAnotherOutside: {
    id: 'app.components.RejectionReason.tenantRentedAnotherOutside',
    defaultMessage: 'Interessado alugou outro imóvel fora do {companyName}',
  },
  tenantGaveUpRenting: {
    id: 'app.components.RejectionReason.tenantGaveUpRenting',
    defaultMessage: 'Interessado desistiu de alugar nesse momento',
  },
  tenantStalled: {
    id: 'app.components.RejectionReason.tenantStalled',
    defaultMessage: 'Interessado não respondeu dentro do prazo de 72h',
  },
  ownerDoesntAgreeWithValue: {
    id: 'app.components.RejectionReason.ownerDoesntAgreeWithValue',
    defaultMessage: 'Proprietário não concorda com o valor de aluguel sugerido',
  },
  ownerDoesntAgreeWithConditions: {
    id: 'app.components.RejectionReason.ownerDoesntAgreeWithConditions',
    defaultMessage:
      'Proprietário não concorda com as solicitações especiais do interessado',
  },
  ownerSoldApartment: {
    id: 'app.components.RejectionReason.ownerSoldApartment',
    defaultMessage: 'Proprietário decidiu vender o imóvel',
  },
  ownerRentedForAnotherTenantOutside: {
    id: 'app.components.RejectionReason.ownerRentedForAnotherTenantOutside',
    defaultMessage: 'Proprietário alugou fora do {companyName}',
  },
  ownerRentedForAnotherTenant5A: {
    id: 'app.components.RejectionReason.ownerRentedForAnotherTenant5A',
    defaultMessage: 'Proprietário alugou esse imóvel para outro interessado',
  },
  ownerGaveUpRenting: {
    id: 'app.components.RejectionReason.ownerGaveUpRenting',
    defaultMessage: 'Proprietário desistiu de alugar esse imóvel',
  },
  ownerStalled: {
    id: 'app.components.RejectionReason.ownerStalled',
    defaultMessage: 'Proprietário não respondeu dentro do prazo de 72h',
  },
  stalledRejection: {
    id: 'app.containers.RentProgress.Offers.stalledRejection',
    defaultMessage: 'Proposta cancelada',
  },
  stalledRejectionTenantStatus: {
    id: 'app.containers.RentProgress.Offers.stalledRejectioTenantStatus',
    defaultMessage: 'Proposta cancelada por falta de resposta do interessado',
  },
  stalledRejectionOwnerStatus: {
    id: 'app.containers.RentProgress.Offers.stalledRejectionOwnerStatus',
    defaultMessage: 'Proposta cancelada por falta de resposta do proprietário',
  },
  houseInactive: {
    id: 'app.containers.RentProgress.Offers.houseInactive',
    defaultMessage: 'Proposta cancelada',
  },
  houseInactiveOwnerStatus: {
    id: 'app.containers.RentProgress.Offers.houseInactiveOwnerStatus',
    defaultMessage:
      'Proposta cancelada por suspensão do imóvel no {companyName}',
  },
  houseUnpublished: {
    id: 'app.containers.RentProgress.Offers.houseUnpublished',
    defaultMessage: 'Proposta cancelada',
  },
  houseUnpublishedOwnerStatus: {
    id: 'app.containers.RentProgress.Offers.houseUnpublishedOwnerStatus',
    defaultMessage: 'Proposta cancelada por despublicação do imóvel',
  },
  // Filters
  filtersSectionTitle: {
    id: 'app.containers.RentProgress.Offers.filtersSectionTitle',
    defaultMessage: 'Visualizar negociações:',
  },
  filtersOptionToday: {
    id: 'app.containers.RentProgress.Offers.filtersOptionToday',
    key: 'TODAY',
    defaultMessage: 'hoje',
  },
  filtersOptionLastXDays: {
    id: 'app.containers.RentProgress.Offers.filtersOptionLastXDays',
    key: `LAST_${TIME_SINCE_OFFER_DRAFT}_DAYS`,
    defaultMessage: 'últimos {timeSinceOfferDraft} dias',
  },
  filtersOptionLimitOfDays: {
    id: 'app.containers.RentProgress.Offers.filtersOptionLimitOfDays',
    key: `LAST_${LIMIT_OF_DAYS}_DAYS`,
    defaultMessage: 'últimos {limitOfDays} dias',
  },
  sortingSectionTitle: {
    id: 'app.containers.RentProgress.Offers.sortingSectionTitle',
    defaultMessage: 'Ordenar por:',
  },
  sortingOptionRecent: {
    id: 'app.containers.RentProgress.Offers.sortingOptionRecent',
    key: 'DESC',
    defaultMessage: 'mais recentes',
  },
  sortingOptionOlder: {
    id: 'app.containers.RentProgress.Offers.sortingOptionOlder',
    key: 'ASC',
    defaultMessage: 'mais antigas',
  },
});
