import Box from '@quintoandar/cozy-core/Box';
import Link from '@quintoandar/cozy-core/Link';
import Typography from '@quintoandar/cozy-core/Typography';
import BrandIcon from '@quintoandar/cozy-icons/QuintoAndar';
import LoginContainer from '@quintoandar/bioma-auth/containers/Login';
import { useFeatureFlag } from 'utils/useFeatureFlag';
import { getAssetsProductName } from 'i18n';
import { FormattedMessage, useIntl } from 'react-intl';
import { getBrandName } from 'utils/i18n/brand';

import { FEATURE_FLAGS } from 'enums/featureFlags';
import messages from './messages';

export const AgentsLogin = (props) => {
  const intl = useIntl();
  const product = getAssetsProductName(intl.locale);

  const enablePortfolioLogin = useFeatureFlag(
    FEATURE_FLAGS.ENABLE_PORTFOLIO_LOGIN,
    { custom: { locale: intl.locale } },
  );

  const urlSearch = new URLSearchParams(window.location.search);

  const redirectPath = urlSearch.get('redirect');

  return (
    <LoginContainer
      customTitle={messages.title}
      location="/"
      {...props}
      product={product}
      alertProps={
        enablePortfolioLogin
          ? {
              title: intl.formatMessage(messages.alertTitle, {
                brand: getBrandName(),
              }),
              icon: BrandIcon,
              children: (
                <div>
                  <Box mb={2}>
                    <Typography>
                      <FormattedMessage
                        {...messages.alertDescription}
                        values={{
                          brand: getBrandName(),
                        }}
                      />
                    </Typography>
                  </Box>
                  <Link
                    href={`${
                      process.env.PORTFOLIO_LOGIN_URL
                    }?externalRedirect=https://${window.location.hostname}${
                      redirectPath && redirectPath !== '/' ? redirectPath : ''
                    }`}
                    message={messages.portfolioLink}
                  />
                </div>
              ),
            }
          : null
      }
    />
  );
};

export default AgentsLogin;
