import { defineMessages } from 'react-intl';

export default defineMessages({
  appBarTitle: {
    id: 'app.containers.DoneVisits.appBarTitle',
    defaultMessage: 'Visitas Realizadas',
  },
  header: {
    id: 'app.containers.DoneVisits.header',
    defaultMessage: `{visits, plural,
      =0 {Nenhum atendimento realizado}
      one {{visitsBold} atendimento realizado}
      other {{visitsBold} visitas realizadas}
    }`,
  },
  headerPendingToFinish: {
    id: 'app.containers.DoneVisits.headerPendingToFinish',
    defaultMessage: `{visits, plural,
      =0 {Nenhum atendimento pendente}
      one {{visitsBold} atendimento pendente}
      other {{visitsBold} atendimentos pendentes}
    }`,
  },
  headerSubtitle: {
    id: 'app.containers.DoneVisits.headerSubtitle',
    defaultMessage: `{visits, plural,
      =0 {Referente ao período de {start} até {end}}
      one {Referente ao período de {start} até {end}}
      other {Referentes ao período de {start} até {end}}
    }`,
  },
  searchHeader: {
    id: 'app.containers.DoneVisits.searchHeader',
    defaultMessage: `{visits, plural,
      =0 {Nenhum atendimento realizado}
      one {# atendimento realizado}
      other {# visitas realizadas}
    }`,
  },
  supportText: {
    id: 'app.containers.DoneVisits.supportText',
    defaultMessage: `{limitDays, plural,
      =0 {Acompanhe as visitas que realizou {today} e o inquilino não enviou a proposta.}
      one {Acompanhe as visitas que realizou {yesterday} e o inquilino não enviou a proposta.}
      other {Acompanhe as visitas que realizou nos últimos {limitDaysBold} {days} e o inquilino não enviou a proposta.}
    }`,
  },
  emptyStateTitle: {
    id: 'app.containers.DoneVisits.emptyStateTitle',
    defaultMessage: 'Não há visitas realizadas nos últimos 60 dias',
  },
  emptyStateSubtitle: {
    id: 'app.containers.DoneVisits.emptyStateSubtitle',
    defaultMessage:
      'Aqui você acompanha as visitas que realizou ou que tiveram algum problema na entrada',
  },
  emptyFiltersTitle: {
    id: 'app.containers.DoneVisits.emptyFiltersTitle',
    defaultMessage: 'Nenhum resultado encontrado',
  },
  emptyFiltersSubtitle: {
    id: 'app.containers.DoneVisits.emptyFiltersSubtitle',
    defaultMessage:
      'Altere os filtros aplicados para buscar suas visitas realizadas ou que tiveram algum problema na entrada',
  },
  loadMore: {
    id: 'app.containers.DoneVisits.loadMore',
    defaultMessage: 'Ver mais visitas',
  },
  filtersSectionTitle: {
    id: 'app.containers.DoneVisits.filtersSectionTitle',
    defaultMessage: 'Visitas Realizadas:',
  },
  filtersStatusSectionTitle: {
    id: 'app.containers.DoneVisits.filtersStatusSectionTitle',
    defaultMessage: 'Visitas que você realizou:',
  },
  filtersOptionToday: {
    id: 'app.containers.DoneVisits.filtersOptionToday',
    key: 'TODAY',
    defaultMessage: 'hoje',
  },
  filtersOptionLastXDays: {
    id: 'app.containers.DoneVisits.filtersOptionLastXDays',
    key: 'LAST_7_DAYS',
    defaultMessage: 'últimos 7 dias',
  },
  filtersOptionLimitOfDays: {
    id: 'app.containers.DoneVisits.filtersOptionLimitOfDays',
    key: 'LAST_30_DAYS',
    defaultMessage: 'últimos 30 dias',
  },
  filtersStatusOptionProposalSent: {
    id: 'app.containers.DoneVisits.filtersStatusOptionProposalSent',
    key: 'PROPOSAL_SENT',
    defaultMessage: 'com proposta enviada',
  },
  filtersStatusOptionProposalNotSent: {
    id: 'app.containers.DoneVisits.filtersStatusOptionProposalNotSent',
    key: 'PROPOSAL_NOT_SENT',
    defaultMessage: 'sem proposta enviada',
  },
  filtersStatusOptionEntranceProblems: {
    id: 'app.containers.DoneVisits.filtersStatusOptionEntranceProblems',
    key: 'ENTRANCE_PROBLEMS',
    defaultMessage: 'com problemas de entrada',
  },
  filtersStatusOptionAll: {
    id: 'app.containers.DoneVisits.filtersStatusOptionAll',
    key: 'ALL',
    defaultMessage: 'todas as visitas',
  },
  sortingSectionTitle: {
    id: 'app.containers.DoneVisits.sortingSectionTitle',
    defaultMessage: 'Classificar por:',
  },
  sortingOptionRecent: {
    id: 'app.containers.DoneVisits.sortingOptionRecent',
    key: 'DESC',
    defaultMessage: 'mais recentes',
  },
  sortingOptionOlder: {
    id: 'app.containers.DoneVisits.sortingOptionOlder',
    key: 'ASC',
    defaultMessage: 'mais antigas',
  },
  proposalNotSent: {
    id: 'app.containers.DoneVisits.proposalNotSent',
    defaultMessage: 'Não enviou proposta',
  },
  proposalSent: {
    id: 'app.containers.DoneVisits.proposalSent',
    defaultMessage: 'Enviou proposta',
  },
  entranceProblems: {
    id: 'app.containers.DoneVisits.entranceProblems',
    defaultMessage: 'Problemas na entrada',
  },
  filtersByPeriodSectionTitle: {
    id: 'app.containers.DoneVisits.filtersByPeriodSectionTitle',
    defaultMessage: 'Visitas realizadas:',
  },
  filtersByPeriodStartDate: {
    id: 'app.containers.DoneVisits.filtersByPeriodStartDate',
    defaultMessage: 'do dia',
  },
  filtersByPeriodEndDate: {
    id: 'app.containers.DoneVisits.filtersByPeriodEndDate',
    defaultMessage: 'até o dia',
  },
  entranceProblemReason: {
    id: 'app.containers.DoneVisits.entranceProblemReason',
    defaultMessage: 'Essa visita não aconteceu porque <TODO> {reason} </TODO>',
  },
  emptyMessage: {
    id: 'app.containers.DoneVisits.emptyMessage',
    defaultMessage: '{whiteSpace}',
    values: {
      whiteSpace: ' ',
    },
  },
  typeVisit: {
    id: 'app.containers.DoneVisits.typeVisit',
    defaultMessage: 'Visita realizada',
  },
  typeAgentSupport: {
    id: 'app.containers.DoneVisits.typeAgentSupport',
    defaultMessage: 'Visita online realizada',
  },
  pending: {
    id: 'app.containers.DoneVisits.pending',
    defaultMessage: 'Pendentes',
  },
  done: {
    id: 'app.containers.DoneVisits.done',
    defaultMessage: 'Concluídas',
  },
  filter: {
    id: 'app.containers.DoneVisits.filter',
    defaultMessage: 'Filtrar',
  },
  visitError: {
    id: 'app.containers.DoneVisits.visitError',
    defaultMessage: 'Erro ao buscar visitas',
  },
});
