import { fetchHouseEntryInformationDetails } from 'api/agents/house';
import {
  RENT_PROGRESS_STATUS_PAGE_VIEWED,
  RENT_PROGRESS_STATUS_CARD_OPENED,
  RENT_PROGRESS_STATUS_DOCUMENTATION,
} from './../constants';

import {
  COUNT_DOCUMENTATIONS_FAILURE,
  COUNT_DOCUMENTATIONS_REQUEST,
  COUNT_DOCUMENTATIONS_SUCCESS,
  FETCH_DOCUMENTATIONS_FAILURE,
  FETCH_DOCUMENTATIONS_REQUEST,
  FETCH_DOCUMENTATIONS_SUCCESS,
  SHOULD_FETCH_DOCUMENTATIONS,
  DOCUMENTATION_HOUSE_FETCH_SUCCESS,
  DOCUMENTATION_USER_FETCH_SUCCESS,
  CONTRACT_FETCH_HOUSE_ENTRY_INFORMATION,
} from './constants';

export const countDocumentationsRequest = ({ filters }) => ({
  type: COUNT_DOCUMENTATIONS_REQUEST,
  payload: {
    filters,
  },
});

export const countDocumentationsFailure = (message) => ({
  type: COUNT_DOCUMENTATIONS_FAILURE,
  payload: {
    message,
  },
});

export const countDocumentationsSuccess = (count) => ({
  type: COUNT_DOCUMENTATIONS_SUCCESS,
  payload: {
    count,
  },
});

export const fetchDocumentationsRequest = ({
  userId,
  agentId,
  filters,
  lastItem,
  businessContext,
}) => ({
  type: FETCH_DOCUMENTATIONS_REQUEST,
  payload: {
    userId,
    agentId,
    filters,
    lastItem,
    businessContext,
  },
});

export const fetchDocumentationsFailure = (message) => ({
  type: FETCH_DOCUMENTATIONS_FAILURE,
  payload: {
    message,
  },
});

export const fetchDocumentationsSuccess = ({
  data,
  agentId,
  businessContext,
}) => ({
  type: FETCH_DOCUMENTATIONS_SUCCESS,
  payload: {
    data,
    agentId,
    businessContext,
  },
});

export const fetchHouseEntryInformation = ({
  agentId,
  houseId,
  businessContext,
}) => {
  const fields = ['type', 'owner', 'ciq', 'listings'];
  return {
    type: CONTRACT_FETCH_HOUSE_ENTRY_INFORMATION,
    promise: fetchHouseEntryInformationDetails({ agentId, houseId, fields }),
    meta: {
      houseId,
      businessContext,
    },
  };
};

export const shouldFetchDocumentations = (status) => ({
  type: SHOULD_FETCH_DOCUMENTATIONS,
  payload: {
    status,
  },
});

export const documentationHouseFetchSuccess = (payload) => ({
  type: DOCUMENTATION_HOUSE_FETCH_SUCCESS,
  payload,
});

export const documentationUserFetchSuccess = (payload) => ({
  type: DOCUMENTATION_USER_FETCH_SUCCESS,
  payload,
});

export const triggerDocumentationPageViewed = () => ({
  type: RENT_PROGRESS_STATUS_PAGE_VIEWED,
  extraProperties: {
    rent_progress_status: RENT_PROGRESS_STATUS_DOCUMENTATION,
  },
});

export const triggerDocumentationStatusCardOpened = () => ({
  type: RENT_PROGRESS_STATUS_CARD_OPENED,
  extraProperties: {
    rent_progress_status: RENT_PROGRESS_STATUS_DOCUMENTATION,
  },
});
