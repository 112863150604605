export const VISIT_STATUS = Object.freeze({
  DONE: 'DONE',
  CANCELED: 'CANCELED',
  SCHEDULED: 'SCHEDULED',
  WAITING_CONFIRMATION: 'WAITING_CONFIRMATION',
  REQUEST_CANCELED: 'REQUEST_CANCELED',
});

export const VISIT_STATUS_WEIGHT = Object.freeze({
  [VISIT_STATUS.DONE]: 1,
  [VISIT_STATUS.CANCELED]: 0,
  [VISIT_STATUS.WAITING_CONFIRMATION]: 2,
  [VISIT_STATUS.SCHEDULED]: 2,
});

export const VISIT_STATUS_V3 = Object.freeze({
  DONE: 'DONE',
  CANCELED: 'CANCELED',
  CONFIRMED: 'CONFIRMED',
  REQUESTED: 'REQUESTED',
  REQUEST_CANCELED: 'REQUEST_CANCELED',
});

export default VISIT_STATUS;
