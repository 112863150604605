import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.RentProgress.Contracts.header',
    defaultMessage: `{contracts, plural,
      =0 {Nenhuma proposta em fase de contrato}
      one {{contractsBold} proposta em fase de contrato}
      other {{contractsBold} propostas em fase de contrato}
    }`,
  },
  headerSubtitle: {
    id: 'app.containers.RentProgress.Contracts.headerSubtitle',
    defaultMessage: `{contracts, plural,
      =0 {Referente ao período de {start} até {end}}
      one {Referente ao período de {start} até {end}}
      other {Referentes ao período de {start} até {end}}
    }`,
  },
  searchHeader: {
    id: 'app.containers.RentProgress.Contracts.searchHeader',
    defaultMessage: `{contracts, plural,
      =0 {Nenhum contrato assinado}
      one {# proposta em fase de contrato}
      other {# propostas em fase de contrato}
    }`,
  },
  supportText: {
    id: 'app.containers.RentProgress.Contracts.supportText',
    defaultMessage: `{limitDays, plural,
      =0 {Acompanhe os contratos que foram assinados {today}.}
      one {Acompanhe os contratos que foram assinados {yesterday}.}
      other {Acompanhe os contratos que foram assinados nos últimos {limitDaysBold} {days}.}
    }`,
  },
  emptyStateTitle: {
    id: 'app.containers.RentProgress.Contracts.emptyStateTitle',
    defaultMessage: 'Não há contratos nos últimos {limitOfDays} dias',
  },
  emptyStateSubtitle: {
    id: 'app.containers.RentProgress.Contracts.emptyStateSubtitle',
    defaultMessage:
      'Aqui você acompanha os contratos assinados ou cancelados das visitas que realizou.',
  },
  emptyFiltersSubtitle: {
    id: 'app.containers.RentProgress.Contracts.emptyFiltersSubtitle',
    defaultMessage:
      'Altere os filtros aplicados para buscar suas propostas em fase de contrato.',
  },
  loadMore: {
    id: 'app.containers.RentProgress.Contracts.loadMore',
    defaultMessage: 'Mais contratos',
  },
  contractSigned: {
    id: 'app.containers.RentProgress.Contracts.statusMessage.contractSigned',
    defaultMessage: 'Contrato assinado',
  },
  contractSignedAt: {
    id: 'app.containers.RentProgress.Contracts.statusMessage.contractSignedAt',
    defaultMessage: 'Contrato assinado em {date}',
  },
  contractTerminated: {
    id: 'app.containers.RentProgress.Contracts.statusMessage.contractTerminated',
    defaultMessage: 'Contrato finalizado',
  },
  contractCancelled: {
    id: 'app.containers.RentProgress.Contracts.statusMessage.contractCancelled',
    defaultMessage: 'Contrato cancelado',
  },
  // Filters
  filtersSectionTitle: {
    id: 'app.containers.RentProgress.Contracts.filtersSectionTitle',
    defaultMessage: 'Contratos em:',
  },
  filtersOptionToday: {
    id: 'app.containers.RentProgress.Contracts.filtersOptionToday',
    key: 'TODAY',
    defaultMessage: 'hoje',
  },
  filtersOptionLastXDays: {
    id: 'app.containers.RentProgress.Contracts.filtersOptionLastXDays',
    key: 'LAST_10_DAYS',
    defaultMessage: 'últimos 10 dias',
  },
  filtersOptionLimitOfDays: {
    id: 'app.containers.RentProgress.Contracts.filtersOptionLimitOfDays',
    key: 'LAST_30_DAYS',
    defaultMessage: 'últimos 30 dias',
  },
  filtersStatusSectionTitle: {
    id: 'app.containers.RentProgress.Contracts.filtersStatusSectionTitle',
    defaultMessage: 'Visualizar contratos:',
  },
  filtersStatusOptionSigned: {
    id: 'app.containers.RentProgress.Contracts.filtersStatusOptionSigned',
    key: 'SIGNED',
    defaultMessage: `{contracts, plural,
      =0 {assinado}
      one {assinado}
      other {assinados}
    }`,
  },
  filtersStatusOptionCancelled: {
    id: 'app.containers.RentProgress.Contracts.filtersStatusOptionCancelled',
    key: 'CANCELLED',
    defaultMessage: `{contracts, plural,
      =0 {cancelado}
      one {cancelado}
      other {cancelados}
    }`,
  },
  filtersStatusOptionAll: {
    id: 'app.containers.RentProgress.Contracts.filtersStatusOptionAll',
    key: 'ALL',
    defaultMessage: 'todos os contratos',
  },
  filtersStatusOptionDraft: {
    id: 'app.containers.RentProgress.Contracts.filtersStatusOptionDraft',
    key: 'DRAFT',
    defaultMessage: 'em fase de minuta',
  },
  filtersStatusOptionWaitingSignature: {
    id: 'app.containers.RentProgress.Contracts.filtersStatusOptionWaitingSignature',
    key: 'WAITING_SIGNATURE',
    defaultMessage: 'em fase de pré-assinaturas',
  },
  sortingSectionTitle: {
    id: 'app.containers.RentProgress.Contracts.sortingSectionTitle',
    defaultMessage: 'Ordenar por:',
  },
  sortingOptionRecent: {
    id: 'app.containers.RentProgress.Contracts.sortingOptionRecent',
    key: 'DESC',
    defaultMessage: 'mais recentes',
  },
  sortingOptionOlder: {
    id: 'app.containers.RentProgress.Contracts.sortingOptionOlder',
    key: 'ASC',
    defaultMessage: 'mais antigas',
  },
  filtersByPeriodStartDate: {
    id: 'app.containers.RentProgress.Contracts.filtersByPeriodStartDate',
    defaultMessage: 'do dia',
  },
  filtersByPeriodEndDate: {
    id: 'app.containers.RentProgress.Contracts.filtersByPeriodEndDate',
    defaultMessage: 'até o dia',
  },
  emptyMessage: {
    id: 'app.containers.RentProgress.Contracts.emptyMessage',
    defaultMessage: '{whiteSpace}',
    values: {
      whiteSpace: ' ',
    },
  },
  waitingContractSignatures: {
    id: 'app.containers.RentProgress.Contracts.waitingContractSignatures',
    defaultMessage: 'Aguardando ambas assinaturas',
  },
  ownerSignedContract: {
    id: 'app.containers.RentProgress.Contracts.ownerSignedContract',
    defaultMessage: 'Proprietário finalizou a assinatura do contrato',
  },
  waitingOwnerSignContract: {
    id: 'app.containers.RentProgress.Contracts.waitingOwnerSignContract',
    defaultMessage: 'Proprietário precisa assinar o contrato',
  },
  waitingOwnerSignContractOwnerStatus: {
    id: 'app.containers.RentProgress.Contracts.waitingOwnerSignContractOwnerStatus',
    defaultMessage:
      'Proprietário tem até {date} às {hour} para assinatura do contrato',
  },
  waitingOwnerSignContractOwnerStatusNoDate: {
    id: 'app.containers.RentProgress.Contracts.waitingOwnerSignContractOwnerStatusNoDate',
    defaultMessage: 'Proprietário precisa assinar o contrato',
  },
  waitingTenantSignContract: {
    id: 'app.containers.RentProgress.Contracts.waitingTenantSignContract',
    defaultMessage: 'Interessado precisa assinar o contrato',
  },
  waitingTenantSignContractTenantStatusNoDate: {
    id: 'app.containers.RentProgress.Contracts.waitingTenantSignContractTenantStatus',
    defaultMessage: 'Interessado precisa assinar o contrato',
  },
  waitingSignaturesValidation: {
    id: 'app.containers.RentProgress.Contracts.waitingSignaturesValidation',
    defaultMessage: 'Aguardando validação das assinaturas',
  },
  tenantSignedContract: {
    id: 'app.containers.RentProgress.Contracts.tenantSignedContract',
    defaultMessage: 'Interessado finalizou a assinatura do contrato',
  },
});
