import { INTERNAL_CHATS_URL } from 'config';

const ROUTES = Object.freeze({
  ACCESS_CODE: 'access-code',
  AGENDA: '/agenda',
  AGENDA_VISIT_DETAIL: '/agenda/detalhes/data/:dateEpoch/imovel/:houseId',
  AGENDA_VISIT_DETAIL_V2: '/agenda/detalhes/:visitCode',
  AGENDA_AGENT_SUPPORT_DETAIL: '/agenda/detalhes/atendimento/:uniqueId',
  AGENT_KEYS: '/minhas-chaves',
  AGENTS_MKT_PATH: 'https://mkt.quintoandar.com.br/corretor/',
  BASE_PATH: '/',
  BASE_PATH_VISIT_SCHEDULING: '/?houseid=:houseId&flow=schedule',
  CONFIRM_ACCOUNT_PATH: 'confirm-account',
  CONTACT_US_PATH: '/contact-us',
  CONTRACT_PATH: '/contrato',
  CREATE_ACCOUNT_PATH: 'create-account',
  DOCUMENTATION_PATH: '/documentacao',
  DONE_VISITS_PATH: '/visitas-realizadas',
  ENTRY_INFORMATION_PATH: '/informacoes-de-entrada',
  ENTRY_INFORMATION_RESULT_LIST_PATH: '/informacoes-de-entrada/:houseId',
  FORBIDDEN_PATH: '/forbidden',
  GET_KEYS_PATH: '/minhas-chaves/imovel/:houseId/retirada',
  LOGIN_PATH: '/auth/login',
  MY_CLIENTS_GROUPED: '/menu-meus-clientes',
  ONGOING_NEGOTIATION: '/em-negociacao',
  CONVERSATIONS_PATH: `${INTERNAL_CHATS_URL}/chats/choose-client`,
  CONVERSATIONS_WITH_OWNERS_PATH: '/chats/corretor-proprietario',
  OFFER_PATH: '/negociacao',
  ONLINE_SUPPORT_PATH: '/atendimento-online',
  RETURN_KEYS_PATH: '/chaves/imovel/:houseId/devolucao',
  OFFERS_PATH: '/propostas',
  SEARCH_CLIENTS: '/buscar-clientes',
  TIME_BLOCK: '/bloqueio-de-horarios',
  VERIFY_EMAIL_PATH: 'verify-email',
  VISITOR_INFO: '/informacoes-visitante/:visitorId',
  VISIT_SCHEDULED_PATH: '/visitas-agendadas',
  TRAZ_QUEM_COMPRA: '/traz-quem-compra',
  CLIENTS_CRM: '/crm-clientes',
  CLIENT_PROFILE: '/client-profile/:visitorId',
  MY_ACTIVITY: '/minha-atividade',
  REDIRECTING: '/redirecting',
});

export default ROUTES;
