const AGENT_SUPPORT_STATUS = Object.freeze({
  COMPLETE: 'COMPLETE',
  IN_PROGRESS: 'IN_PROGRESS',
  PENDING: 'PENDING',
  TRANSFERRED: 'TRANSFERRED',
});

export const AGENT_SUPPORT_ONGOING_STATUS = [
  AGENT_SUPPORT_STATUS.IN_PROGRESS,
  AGENT_SUPPORT_STATUS.PENDING,
];

export default AGENT_SUPPORT_STATUS;
