import { fromJS } from 'immutable';

import CONTENT from 'components/Dialog/constants';

import snackbarMessages from 'components/Snackbar/messages';
import {
  CHECKED_AGENT,
  UNAUTHORIZED_AGENT,
  FETCH_AGENT_DATA_FAILURE,
  FETCH_AGENT_DATA_SUCCESS,
  FIREBASE_AUTH_STATE_CHANGED,
  HIDE_DIALOG,
  HIDE_LOADING,
  HIDE_SNACKBAR,
  OFFER_LINK_FAILURE,
  OFFER_LINK_REQUEST,
  OFFER_LINK_SUCCESS,
  SHOW_DIALOG,
  SHOW_LOADING,
  SHOW_SNACKBAR,
  UPDATE_DIALOG_STATUS,
  HIDE_ERROR_DIALOG,
  SHOW_ERROR_DIALOG,
  FETCH_SHOW_AGENT_KEYS,
  FETCH_HAS_UNREAD_MESSAGES,
} from 'containers/App/constants';
import messages from 'containers/App/messages';
import BUSINESS_CONTEXTS from 'enums/businessContexts';
import { FIREBASE_AUTH_STATUS } from '../../api/firebase/constants';
import { OFFERS_TAB } from '../../constants';
import {
  handleFetchAgentData,
  handleFirebaseAuthStatus,
  handleShowErrorDialog,
  handleFetchHasUnreadMessages,
  handleFetchShowAgentKeys,
} from './handlers';

export const initialState = fromJS({
  hasLoadedRating: false,
  agentData: {
    hasReviews: false,
    rating: 0,
    businessContext: [],
  },
  error: {
    message: '',
  },
  hasError: false,
  isLoading: false,
  snackbar: {
    isVisible: false,
    message: snackbarMessages.emptyMessage,
    timeout: 5000,
    actionTitle: null,
  },
  dialog: {
    entered: false,
    isVisible: false,
    tab: OFFERS_TAB,
    screen: CONTENT.FILTER,
    businessContext: BUSINESS_CONTEXTS.FOR_RENT,
  },
  errorDialog: {
    isOpen: false,
    body: '',
    title: '',
  },
  refreshedProfile: false,
  checkedAgent: false,
  unauthorizedAgent: false,
  firebaseAuthStatus: FIREBASE_AUTH_STATUS.NOT_VERIFIED,
  hasUnreadMessages: false,
  showAgentKeys: null,
});

function appReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case HIDE_SNACKBAR:
      return state
        .setIn(['snackbar', 'isVisible'], false)
        .setIn(
          ['snackbar', 'message'],
          initialState.getIn(['snackbar', 'message']),
        );
    case SHOW_SNACKBAR:
      return state
        .setIn(['snackbar', 'isVisible'], true)
        .setIn(['snackbar', 'timeout'], payload.timeout)
        .setIn(['snackbar', 'actionTitle'], payload.actionTitle)
        .setIn(['snackbar', 'message'], payload.message)
        .setIn(['snackbar', 'color'], payload.color);
    case HIDE_LOADING:
      return state.set('isLoading', false);
    case SHOW_LOADING:
      return state.set('isLoading', true);
    case HIDE_DIALOG:
      return state
        .setIn(['dialog', 'isVisible'], false)
        .setIn(['dialog', 'entered'], false);
    case SHOW_DIALOG:
      return state
        .setIn(['dialog', 'isVisible'], true)
        .setIn(['dialog', 'tab'], payload.tab)
        .setIn(['dialog', 'screen'], payload.screen)
        .setIn(
          ['dialog', 'businessContext'],
          payload.businessContext || BUSINESS_CONTEXTS.FOR_RENT,
        );
    case OFFER_LINK_REQUEST:
      return state.set('isLoading', true);
    case OFFER_LINK_FAILURE:
      return state
        .set('isLoading', false)
        .set('hasError', true)
        .setIn(['error', 'message'], payload.message)
        .setIn(['snackbar', 'isVisible'], true)
        .setIn(['snackbar', 'message'], messages.resendProposalLinkError)
        .setIn(
          ['snackbar', 'timeout'],
          initialState.getIn(['snackbar', 'timeout']),
        );
    case OFFER_LINK_SUCCESS:
      return state
        .set('isLoading', false)
        .set('hasError', false)
        .setIn(['snackbar', 'isVisible'], true)
        .setIn(['snackbar', 'message'], messages.resendProposalLinkSuccess)
        .setIn(
          ['snackbar', 'timeout'],
          initialState.getIn(['snackbar', 'timeout']),
        );
    case CHECKED_AGENT:
      return state.merge({
        checkedAgent: true,
        isCheckedAgent: payload && payload.isActive,
      });
    case UNAUTHORIZED_AGENT:
      return state.merge({ unauthorizedAgent: true });
    case FETCH_AGENT_DATA_SUCCESS:
      return handleFetchAgentData(state, payload);
    case FETCH_AGENT_DATA_FAILURE:
      return state
        .set('hasError', true)
        .setIn(['error', 'message'], payload.message);
    case UPDATE_DIALOG_STATUS:
      return state.setIn(['dialog', 'entered'], payload.entered);
    case FIREBASE_AUTH_STATE_CHANGED:
      return handleFirebaseAuthStatus(state, payload);
    case HIDE_ERROR_DIALOG:
      return state.mergeIn(['errorDialog', 'isOpen'], false);
    case SHOW_ERROR_DIALOG:
      return handleShowErrorDialog(state, payload);
    case FETCH_HAS_UNREAD_MESSAGES:
      return handleFetchHasUnreadMessages(state, action);
    case FETCH_SHOW_AGENT_KEYS:
      return handleFetchShowAgentKeys(state, action);
    default:
      return state;
  }
}

export default appReducer;
