export const VISIT_STATUS = Object.freeze({
  ACCEPT_NEW: 'ACCEPT_NEW',
  ACCEPT_ALWAYS: 'ACCEPT_ALWAYS',
});

export const SUGGESTIONS = Object.freeze({
  MAX_DISTANCE: 500,
  MAX_DAYS_SCHEDULES: 3,
  SIZE: 3,
});

export const VISIT_HOUSE_OWNER_CHAT_UNAVAILABLE_FEATURE_FLAG =
  'VISIT_HOUSE_OWNER_CHAT_UNAVAILABLE_FEATURE_FLAG';
