import { FORBIDDEN, NOT_FOUND } from 'http-status-codes';
import { handle } from 'redux-pack';
import { SITE_URL } from 'config';

import {
  ENTRY_INFO_FIND_PROPERTY_ANALYTICS,
  ENTRY_INFO_PAGE_VIEWED_ANALYTICS,
  ENTRY_INFO_CHAT_WITH_AGENT_CLICKED_ANALYTICS,
  ENTRY_INFO_CHAT_WITH_OWNER_CLICKED_ANALYTICS,
  ENTRY_INFO_NEW_VISIT_CLICKED_ANALYTICS,
  ENTRY_INFO_SEARCH_SUBMITTED_ANALYTICS,
  ENTRY_INFO_URL_COPIED_ANALYTICS,
  FETCH_HOUSE_ENTRY_INFORMATION,
  HOUSE_NOT_AVAILABLE,
  KEYS_WITH_AGENT,
  KEYS_WITH_OWNER,
  KEYS_ON_LOCKBOX,
  KEYS_ON_FRONT_DOOR,
  KEYS_ON_LOCKER,
  PASSWORD_WITH_OWNER,
  PASSWORD_PROVIDED,
  BIOMETRY_WITH_OWNER,
  UNKNOWN_LABEL,
  OWNER_IN_HOUSE,
  TENANT_IN_HOUSE,
  ENTRANCE_STATUS,
  KEYS_WITH_OWNER_TO_AGENT,
} from 'utils/entryInfo/constants';
import { entryInfoLabel } from 'utils/parsers/parseEntryInfoLabel';
import { PAGE_NAMES } from '../constants';
import { immutablePage } from '../utils';

export const mapLabelToEntranceStatus = (label) => {
  switch (label) {
    case HOUSE_NOT_AVAILABLE:
      return ENTRANCE_STATUS.UNAVAILABLE;
    case KEYS_WITH_AGENT:
    case KEYS_WITH_OWNER_TO_AGENT:
      return ENTRANCE_STATUS.KEYS_WITH_AGENT;
    case KEYS_WITH_OWNER:
    case PASSWORD_WITH_OWNER:
    case BIOMETRY_WITH_OWNER:
    case OWNER_IN_HOUSE:
    case TENANT_IN_HOUSE:
      return ENTRANCE_STATUS.BOOK_FIRST;
    case KEYS_ON_LOCKBOX:
    case KEYS_ON_FRONT_DOOR:
    case KEYS_ON_LOCKER:
    case PASSWORD_PROVIDED:
      return ENTRANCE_STATUS.FREE;
    case UNKNOWN_LABEL:
    default:
      return ENTRANCE_STATUS.NA;
  }
};

const isActionValidResponse = (action) =>
  action.payload && action.payload.response;

const isStatusForbidden = (action) =>
  isActionValidResponse(action) && action.payload.response.status === FORBIDDEN;

const isStatusNotFound = (action) =>
  isActionValidResponse(action) && action.payload.response.status === NOT_FOUND;

export const handleEntryInfoActions = (
  eventDispatcherHandler,
  action,
  state,
) => {
  switch (action.type) {
    case ENTRY_INFO_PAGE_VIEWED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'entrance_info_page_viewed',
        immutablePage(PAGE_NAMES.ENTRY_INFO),
      );
      break;

    case ENTRY_INFO_FIND_PROPERTY_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'entrance_info_find_property_clicked',
        immutablePage(PAGE_NAMES.ENTRY_INFO),
      );
      window.open(SITE_URL, '_blank');
      break;

    case ENTRY_INFO_CHAT_WITH_AGENT_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'whtsppciqtpa_agentspwa_pageimovel_clicked',
        immutablePage(PAGE_NAMES.ENTRY_INFO),
        action.extraProperties,
      );
      break;

    case ENTRY_INFO_CHAT_WITH_OWNER_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'chatcrpp_access_chat_clicked',
        immutablePage(PAGE_NAMES.ENTRY_INFO),
      );
      break;

    case ENTRY_INFO_URL_COPIED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'entance_info_url_copied',
        immutablePage(PAGE_NAMES.ENTRY_INFO),
      );
      break;

    case FETCH_HOUSE_ENTRY_INFORMATION:
      handle(state, action, {
        success: (prevState) => {
          const { payload, meta } = action;

          const { accessType, status, agentHasKeys, keysWithAgent } = payload;

          const { houseId } = meta;

          const label = entryInfoLabel({
            accessType,
            listingStatus: status,
            agentHasKeys,
            keysWithAgent,
          });
          const entranceStatus = mapLabelToEntranceStatus(label);

          eventDispatcherHandler.logWithDefaultEventProps(
            'entrance_info_property_viewed',
            immutablePage(PAGE_NAMES.ENTRY_INFO),
            {
              entrance_status: entranceStatus,
              imovel_id: houseId,
            },
          );
          return prevState;
        },
        failure: (prevState) => {
          if (isStatusForbidden(action)) {
            eventDispatcherHandler.logWithDefaultEventProps(
              'entrance_info_outside_region',
              immutablePage(PAGE_NAMES.ENTRY_INFO),
            );

            return prevState;
          }

          if (isStatusNotFound(action)) {
            eventDispatcherHandler.logWithDefaultEventProps(
              'entrance_info_invalid_id',
              immutablePage(PAGE_NAMES.ENTRY_INFO),
            );

            return prevState;
          }

          return prevState;
        },
      });
      break;

    case ENTRY_INFO_SEARCH_SUBMITTED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'entrance_info_search_submitted',
        immutablePage(PAGE_NAMES.ENTRY_INFO),
      );
      break;

    case ENTRY_INFO_NEW_VISIT_CLICKED_ANALYTICS:
      eventDispatcherHandler.logWithDefaultEventProps(
        'entrance_info_new_visit_clicked',
        immutablePage(PAGE_NAMES.ENTRY_INFO),
      );
      break;

    default:
      break;
  }
};

export default handleEntryInfoActions;
