/**
 *
 * Asynchronously loads the component for AgentSupportDetailContainer
 *
 */
import Loadable from 'react-loadable';

export default Loadable({
  loader: () =>
    import(/* webpackChunkName: "AgentSupportDetailContainer" */ './index'),
  loading: () => null,
});
